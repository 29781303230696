/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const setUserRisk = /* GraphQL */ `
  mutation SetUserRisk($input: SetUserRiskInput!) {
    setUserRisk(input: $input) {
      success
      data
      errorCode
      __typename
    }
  }
`;
export const sendLtp = /* GraphQL */ `
  mutation SendLtp($userId: ID!, $shareholderId: ID) {
    sendLtp(userId: $userId, shareholderId: $shareholderId) {
      success
      data
      errorCode
      __typename
    }
  }
`;
export const verifyLtp = /* GraphQL */ `
  mutation VerifyLtp($userId: ID!, $code: String!) {
    verifyLtp(userId: $userId, code: $code) {
      success
      data
      errorCode
      __typename
    }
  }
`;
export const setUserLimitWaiver = /* GraphQL */ `
  mutation SetUserLimitWaiver($userId: ID!, $waiveUntil: AWSDateTime) {
    setUserLimitWaiver(userId: $userId, waiveUntil: $waiveUntil) {
      success
      data
      errorCode
      __typename
    }
  }
`;
export const setUserLimits = /* GraphQL */ `
  mutation SetUserLimits($userId: ID, $userLimitsInput: UserLimitsInput) {
    setUserLimits(userId: $userId, userLimitsInput: $userLimitsInput) {
      success
      data
      errorCode
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserProfileInput!) {
    updateUser(input: $input) {
      id
      accountType
      membership {
        id
        name
        price
        dailyLimit
        monthlyLimit
        createdAt
        updatedAt
        __typename
      }
      name
      lastName
      birthdate
      country
      address
      city
      postalCode
      email
      emailVerified
      phoneCode
      phoneNumber
      phoneNumberVerified
      kyc {
        status
        result
        caseId
        applicantId
        reason
        __typename
      }
      beneficiaries {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        bankDetails {
          name
          value
          __typename
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
        __typename
      }
      externalBankAccounts {
        name
        number
        iban
        swift
        sort
        __typename
      }
      poundsAccount {
        id
        name
        label
        type
        currency
        iban
        bban
        balance
        lastTx
        __typename
      }
      invitedBy {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      readNotifications
      readOnboardings
      amountsSent {
        daily
        monthly
        __typename
      }
      profileImage
      paymentMethods {
        last4
        scheme
        provider
        customerId
        expiryMonth
        id
        expiryYear
        type
        __typename
      }
      companyInfo {
        tradingName
        primaryHolderName
        industryType
        registeredAddress
        businessDescription
        annualTurnover
        stablishmentDate
        tradingStartDate
        transactionVolumen
        shareholders {
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          __typename
        }
        internationalActivity
        kybCompanyCode
        __typename
      }
      pushNotificationsConfig {
        token
        endpointArn
        __typename
      }
      smsMarketingConsent
      pushMarketingConsent
      emailMarketingConsent
      smsAllowed
      pushAllowed
      emailAllowed
      forbiddenCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      intercomProfileUrl
      accountStatus
      type
      passwordResetAt
      passwordResetBy
      customerId
      businessDetail
      shareholders
      SignUpAddressPersonalDetails
      SignUpFinancialDetails
      SignUpIndividualPersonalDetails
      transactionsInfo
      businessType
      author
      __typename
    }
  }
`;
export const completeUserProfile = /* GraphQL */ `
  mutation CompleteUserProfile($input: CompleteUserProfileInput!) {
    completeUserProfile(input: $input) {
      id
      accountType
      membership {
        id
        name
        price
        dailyLimit
        monthlyLimit
        createdAt
        updatedAt
        __typename
      }
      name
      lastName
      birthdate
      country
      address
      city
      postalCode
      email
      emailVerified
      phoneCode
      phoneNumber
      phoneNumberVerified
      kyc {
        status
        result
        caseId
        applicantId
        reason
        __typename
      }
      beneficiaries {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        bankDetails {
          name
          value
          __typename
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
        __typename
      }
      externalBankAccounts {
        name
        number
        iban
        swift
        sort
        __typename
      }
      poundsAccount {
        id
        name
        label
        type
        currency
        iban
        bban
        balance
        lastTx
        __typename
      }
      invitedBy {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      readNotifications
      readOnboardings
      amountsSent {
        daily
        monthly
        __typename
      }
      profileImage
      paymentMethods {
        last4
        scheme
        provider
        customerId
        expiryMonth
        id
        expiryYear
        type
        __typename
      }
      companyInfo {
        tradingName
        primaryHolderName
        industryType
        registeredAddress
        businessDescription
        annualTurnover
        stablishmentDate
        tradingStartDate
        transactionVolumen
        shareholders {
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          __typename
        }
        internationalActivity
        kybCompanyCode
        __typename
      }
      pushNotificationsConfig {
        token
        endpointArn
        __typename
      }
      smsMarketingConsent
      pushMarketingConsent
      emailMarketingConsent
      smsAllowed
      pushAllowed
      emailAllowed
      forbiddenCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      intercomProfileUrl
      accountStatus
      type
      passwordResetAt
      passwordResetBy
      customerId
      businessDetail
      shareholders
      SignUpAddressPersonalDetails
      SignUpFinancialDetails
      SignUpIndividualPersonalDetails
      transactionsInfo
      businessType
      author
      __typename
    }
  }
`;
export const updateUserBeneficiary = /* GraphQL */ `
  mutation UpdateUserBeneficiary($input: BeneficiaryInput) {
    updateUserBeneficiary(input: $input) {
      id
      status
      userId
      accountType
      name
      lastName
      country {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
        __typename
      }
      address
      city
      postalCode
      email
      phoneCode
      phoneNumber
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      bankDetails {
        name
        value
        __typename
      }
      userProfileId
      usageLevel
      skipInternalCheck
      currencyRequirementType
      beneficiaryCountryId
      beneficiaryCurrencyId
      __typename
    }
  }
`;
export const removeUserBeneficiary = /* GraphQL */ `
  mutation RemoveUserBeneficiary($input: DeleteBeneficiaryInput) {
    removeUserBeneficiary(input: $input) {
      id
      status
      userId
      accountType
      name
      lastName
      country {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
        __typename
      }
      address
      city
      postalCode
      email
      phoneCode
      phoneNumber
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      bankDetails {
        name
        value
        __typename
      }
      userProfileId
      usageLevel
      skipInternalCheck
      currencyRequirementType
      beneficiaryCountryId
      beneficiaryCurrencyId
      __typename
    }
  }
`;
export const initTransaction = /* GraphQL */ `
  mutation InitTransaction($input: TransactionInput) {
    initTransaction(input: $input) {
      id
      type
      userIdFrom
      userIdTo
      userFrom {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      externalUserFrom {
        name
        lastName
        iban
        __typename
      }
      userTo {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      beneficiary {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        bankDetails {
          name
          value
          __typename
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
        __typename
      }
      fromCurrency
      toCurrency
      sendingAmount
      exchangeRate
      receivingAmount
      fee
      transferFee
      discount {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
          __typename
        }
        __typename
      }
      reference
      purpose
      status
      steps {
        timestamp
        description
        order
        status
        __typename
      }
      errorCode
      method
      userFromNote
      userFromAttachment
      userToNote
      userToAttachment
      createdAt
      internalId
      failureReason
      adminAttachments
      debtorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
        __typename
      }
      creditorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
        __typename
      }
      endToEndId
      rateQuoteId
      runningBalanceFrom
      runningBalanceTo
      __typename
    }
  }
`;
export const sendMoney = /* GraphQL */ `
  mutation SendMoney($input: TransactionInput) {
    sendMoney(input: $input)
  }
`;
export const inviteUser = /* GraphQL */ `
  mutation InviteUser($input: InviteBeneficiaryInput) {
    inviteUser(input: $input)
  }
`;
export const confirmTransaction = /* GraphQL */ `
  mutation ConfirmTransaction($input: ConfirmTransactionInput) {
    confirmTransaction(input: $input)
  }
`;
export const editTransactionFrom = /* GraphQL */ `
  mutation EditTransactionFrom($input: EditTransactionInput) {
    editTransactionFrom(input: $input) {
      id
      type
      userIdFrom
      userIdTo
      userFrom {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      externalUserFrom {
        name
        lastName
        iban
        __typename
      }
      userTo {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      beneficiary {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        bankDetails {
          name
          value
          __typename
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
        __typename
      }
      fromCurrency
      toCurrency
      sendingAmount
      exchangeRate
      receivingAmount
      fee
      transferFee
      discount {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
          __typename
        }
        __typename
      }
      reference
      purpose
      status
      steps {
        timestamp
        description
        order
        status
        __typename
      }
      errorCode
      method
      userFromNote
      userFromAttachment
      userToNote
      userToAttachment
      createdAt
      internalId
      failureReason
      adminAttachments
      debtorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
        __typename
      }
      creditorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
        __typename
      }
      endToEndId
      rateQuoteId
      runningBalanceFrom
      runningBalanceTo
      __typename
    }
  }
`;
export const editTransactionTo = /* GraphQL */ `
  mutation EditTransactionTo($input: EditTransactionInput) {
    editTransactionTo(input: $input) {
      id
      type
      userIdFrom
      userIdTo
      userFrom {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      externalUserFrom {
        name
        lastName
        iban
        __typename
      }
      userTo {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      beneficiary {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        bankDetails {
          name
          value
          __typename
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
        __typename
      }
      fromCurrency
      toCurrency
      sendingAmount
      exchangeRate
      receivingAmount
      fee
      transferFee
      discount {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
          __typename
        }
        __typename
      }
      reference
      purpose
      status
      steps {
        timestamp
        description
        order
        status
        __typename
      }
      errorCode
      method
      userFromNote
      userFromAttachment
      userToNote
      userToAttachment
      createdAt
      internalId
      failureReason
      adminAttachments
      debtorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
        __typename
      }
      creditorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
        __typename
      }
      endToEndId
      rateQuoteId
      runningBalanceFrom
      runningBalanceTo
      __typename
    }
  }
`;
export const editTransaction = /* GraphQL */ `
  mutation EditTransaction($input: EditTransactionInput) {
    editTransaction(input: $input) {
      id
      type
      userIdFrom
      userIdTo
      userFrom {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      externalUserFrom {
        name
        lastName
        iban
        __typename
      }
      userTo {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      beneficiary {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        bankDetails {
          name
          value
          __typename
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
        __typename
      }
      fromCurrency
      toCurrency
      sendingAmount
      exchangeRate
      receivingAmount
      fee
      transferFee
      discount {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
          __typename
        }
        __typename
      }
      reference
      purpose
      status
      steps {
        timestamp
        description
        order
        status
        __typename
      }
      errorCode
      method
      userFromNote
      userFromAttachment
      userToNote
      userToAttachment
      createdAt
      internalId
      failureReason
      adminAttachments
      debtorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
        __typename
      }
      creditorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
        __typename
      }
      endToEndId
      rateQuoteId
      runningBalanceFrom
      runningBalanceTo
      __typename
    }
  }
`;
export const editTransferFee = /* GraphQL */ `
  mutation EditTransferFee($input: EditTransferFeeInput) {
    editTransferFee(input: $input) {
      paymentMethod
      fee
      __typename
    }
  }
`;
export const createWallet = /* GraphQL */ `
  mutation CreateWallet($input: CreateWalletInput) {
    createWallet(input: $input) {
      id
      currency
      balance
      history
      routingNumber
      accountNumber
      bankAddress
      bankName
      status
      type
      iban
      __typename
    }
  }
`;
export const removeWallet = /* GraphQL */ `
  mutation RemoveWallet($input: DeleteWalletInput) {
    removeWallet(input: $input) {
      id
      currency
      balance
      history
      routingNumber
      accountNumber
      bankAddress
      bankName
      status
      type
      iban
      __typename
    }
  }
`;
export const setWalletStatus = /* GraphQL */ `
  mutation SetWalletStatus($input: SetWalletStatusInput) {
    setWalletStatus(input: $input) {
      id
      currency
      balance
      history
      routingNumber
      accountNumber
      bankAddress
      bankName
      status
      type
      iban
      __typename
    }
  }
`;
export const confirmConversion = /* GraphQL */ `
  mutation ConfirmConversion($input: ConfirmTransactionInput) {
    confirmConversion(input: $input)
  }
`;
export const sendMessage = /* GraphQL */ `
  mutation SendMessage($input: CreateMessageInput) {
    sendMessage(input: $input) {
      conversationId
      type
      timestamp
      sender {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      content
      read
      __typename
    }
  }
`;
export const readMessage = /* GraphQL */ `
  mutation ReadMessage($input: ReadMessageInput) {
    readMessage(input: $input) {
      conversationId
      type
      timestamp
      sender {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      content
      read
      __typename
    }
  }
`;
export const registerMobileToken = /* GraphQL */ `
  mutation RegisterMobileToken($token: String!) {
    registerMobileToken(token: $token)
  }
`;
export const removeMobileToken = /* GraphQL */ `
  mutation RemoveMobileToken($token: String!) {
    removeMobileToken(token: $token)
  }
`;
export const paymentGatewayRequest = /* GraphQL */ `
  mutation PaymentGatewayRequest($input: RequestPaymentInput!) {
    paymentGatewayRequest(input: $input) {
      success
      errorCode
      errorMessage
      internalMessage
      redirectLink
      __typename
    }
  }
`;
export const paymentGatewayRemoveMethod = /* GraphQL */ `
  mutation PaymentGatewayRemoveMethod($input: RequestPaymentInput!) {
    paymentGatewayRemoveMethod(input: $input) {
      success
      errorCode
      errorMessage
      internalMessage
      redirectLink
      __typename
    }
  }
`;
export const cbCreateVirtualMCCYAccount = /* GraphQL */ `
  mutation CbCreateVirtualMCCYAccount {
    cbCreateVirtualMCCYAccount {
      success
      errorCode
      errorMessage
      internalMessage
      __typename
    }
  }
`;
export const cbCreateVirtualAccount = /* GraphQL */ `
  mutation CbCreateVirtualAccount($input: CBCreateNewVirtualInput) {
    cbCreateVirtualAccount(input: $input) {
      success
      iban
      errorMessage
      __typename
    }
  }
`;
export const kybCreateCase = /* GraphQL */ `
  mutation KybCreateCase($company: String, $country: String, $code: String) {
    kybCreateCase(company: $company, country: $country, code: $code) {
      success
      data
      __typename
    }
  }
`;
export const kybCreateShareholder = /* GraphQL */ `
  mutation KybCreateShareholder($data: AWSJSON) {
    kybCreateShareholder(data: $data) {
      success
      data
      __typename
    }
  }
`;
export const kybUploadDocument = /* GraphQL */ `
  mutation KybUploadDocument(
    $type: AccountType
    $name: String
    $path: String
    $commonCaseId: String
  ) {
    kybUploadDocument(type: $type, name: $name, path: $path, commonCaseId: $commonCaseId) {
      success
      data
      __typename
    }
  }
`;
export const kybRequestDocument = /* GraphQL */ `
  mutation KybRequestDocument(
    $type: AccountType
    $documents: [String]
    $email: String
    $phone: String
    $caseCommonId: String
  ) {
    kybRequestDocument(
      type: $type
      documents: $documents
      email: $email
      phone: $phone
      caseCommonId: $caseCommonId
    ) {
      success
      data
      __typename
    }
  }
`;
export const upgradeMembership = /* GraphQL */ `
  mutation UpgradeMembership($id: ID!) {
    upgradeMembership(id: $id) {
      success
      data
      errorCode
      __typename
    }
  }
`;
export const cancelDirectDebitMandate = /* GraphQL */ `
  mutation CancelDirectDebitMandate($mandateId: ID!) {
    cancelDirectDebitMandate(mandateId: $mandateId) {
      success
      data
      errorCode
      __typename
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry($input: CreateCountryInput) {
    createCountry(input: $input) {
      id
      iso
      iso2
      region {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      defaultCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      active
      name
      phoneCode
      order
      companyRequirements
      personalRequirements
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry($input: UpdateCountryInput) {
    updateCountry(input: $input) {
      id
      iso
      iso2
      region {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      defaultCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      active
      name
      phoneCode
      order
      companyRequirements
      personalRequirements
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry($input: DeleteCountryInput) {
    deleteCountry(input: $input) {
      id
      iso
      iso2
      region {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      defaultCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      active
      name
      phoneCode
      order
      companyRequirements
      personalRequirements
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCurrency = /* GraphQL */ `
  mutation CreateCurrency($input: CreateCurrencyInput) {
    createCurrency(input: $input) {
      id
      iso
      active
      name
      walletBgColor
      walletTextColor
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCurrency = /* GraphQL */ `
  mutation UpdateCurrency($input: UpdateCurrencyInput) {
    updateCurrency(input: $input) {
      id
      iso
      active
      name
      walletBgColor
      walletTextColor
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCurrency = /* GraphQL */ `
  mutation DeleteCurrency($input: DeleteCurrencyInput) {
    deleteCurrency(input: $input) {
      id
      iso
      active
      name
      walletBgColor
      walletTextColor
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const putCurrencyRequirement = /* GraphQL */ `
  mutation PutCurrencyRequirement($input: PutCurrencyRequirementInput) {
    putCurrencyRequirement(input: $input) {
      currency
      type
      name
      requirements
      updatedAt
      __typename
    }
  }
`;
export const editCurrencyRequirement = /* GraphQL */ `
  mutation EditCurrencyRequirement($input: PutCurrencyRequirementInput) {
    editCurrencyRequirement(input: $input) {
      currency
      type
      name
      requirements
      updatedAt
      __typename
    }
  }
`;
export const deleteCurrencyRequirement = /* GraphQL */ `
  mutation DeleteCurrencyRequirement($input: DeleteCurrencyRequirementInput) {
    deleteCurrencyRequirement(input: $input) {
      currency
      type
      name
      requirements
      updatedAt
      __typename
    }
  }
`;
export const createPair = /* GraphQL */ `
  mutation CreatePair($input: CreatePairInput) {
    createPair(input: $input) {
      id
      status
      from {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      to {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      regionDestination {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      destination {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
        __typename
      }
      rates {
        intervalIni
        intervalEnd
        rateMode
        rateFrom
        rateTo
        feeMode
        fee
        percentageFee
        __typename
      }
      lastExchangeRateBuy
      lastExchangeRateSell
      createdAt
      updatedAt
      sellRateOverride
      profitMargin {
        type
        value
        __typename
      }
      __typename
    }
  }
`;
export const updatePair = /* GraphQL */ `
  mutation UpdatePair($input: UpdatePairInput) {
    updatePair(input: $input) {
      id
      status
      from {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      to {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      regionDestination {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      destination {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
        __typename
      }
      rates {
        intervalIni
        intervalEnd
        rateMode
        rateFrom
        rateTo
        feeMode
        fee
        percentageFee
        __typename
      }
      lastExchangeRateBuy
      lastExchangeRateSell
      createdAt
      updatedAt
      sellRateOverride
      profitMargin {
        type
        value
        __typename
      }
      __typename
    }
  }
`;
export const deletePair = /* GraphQL */ `
  mutation DeletePair($input: DeletePairInput) {
    deletePair(input: $input) {
      id
      status
      from {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      to {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      regionDestination {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      destination {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
        __typename
      }
      rates {
        intervalIni
        intervalEnd
        rateMode
        rateFrom
        rateTo
        feeMode
        fee
        percentageFee
        __typename
      }
      lastExchangeRateBuy
      lastExchangeRateSell
      createdAt
      updatedAt
      sellRateOverride
      profitMargin {
        type
        value
        __typename
      }
      __typename
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam($input: CreateTeamInput) {
    createTeam(input: $input) {
      id
      name
      email
      active
      accountType
      permissions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam($input: UpdateTeamInput) {
    updateTeam(input: $input) {
      id
      name
      email
      active
      accountType
      permissions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam($input: DeleteTeamInput) {
    deleteTeam(input: $input) {
      id
      name
      email
      active
      accountType
      permissions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFee = /* GraphQL */ `
  mutation CreateFee($input: CreateFeeInput) {
    createFee(input: $input) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      paymentType
      rates {
        intervalIni
        intervalEnd
        feePercent
        feeAbsolute
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFee = /* GraphQL */ `
  mutation UpdateFee($input: UpdateFeeInput) {
    updateFee(input: $input) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      paymentType
      rates {
        intervalIni
        intervalEnd
        feePercent
        feeAbsolute
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFee = /* GraphQL */ `
  mutation DeleteFee($input: DeleteFeeInput) {
    deleteFee(input: $input) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      paymentType
      rates {
        intervalIni
        intervalEnd
        feePercent
        feeAbsolute
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLocalPair = /* GraphQL */ `
  mutation CreateLocalPair($input: CreateLocalPairInput) {
    createLocalPair(input: $input) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      region {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      country {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
        __typename
      }
      rates {
        intervalIni
        intervalEnd
        feeMode
        fee
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLocalPair = /* GraphQL */ `
  mutation UpdateLocalPair($input: UpdateLocalPairInput) {
    updateLocalPair(input: $input) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      region {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      country {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
        __typename
      }
      rates {
        intervalIni
        intervalEnd
        feeMode
        fee
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLocalPair = /* GraphQL */ `
  mutation DeleteLocalPair($input: DeleteLocalPairInput) {
    deleteLocalPair(input: $input) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      region {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      country {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
        __typename
      }
      rates {
        intervalIni
        intervalEnd
        feeMode
        fee
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConfig = /* GraphQL */ `
  mutation CreateConfig($input: CreateConfigInput) {
    createConfig(input: $input) {
      id
      key
      value
      __typename
    }
  }
`;
export const updateConfig = /* GraphQL */ `
  mutation UpdateConfig($input: UpdateConfigInput) {
    updateConfig(input: $input) {
      id
      key
      value
      __typename
    }
  }
`;
export const deleteConfig = /* GraphQL */ `
  mutation DeleteConfig($input: DeleteConfigInput) {
    deleteConfig(input: $input) {
      id
      key
      value
      __typename
    }
  }
`;
export const createDiscount = /* GraphQL */ `
  mutation CreateDiscount($input: CreateDiscountInput) {
    createDiscount(input: $input) {
      id
      code
      type
      amount
      totalUses
      totalUniqueUses
      expire
      discountUses {
        userId
        date
        __typename
      }
      __typename
    }
  }
`;
export const updateDiscount = /* GraphQL */ `
  mutation UpdateDiscount($input: UpdateDiscountInput) {
    updateDiscount(input: $input) {
      id
      code
      type
      amount
      totalUses
      totalUniqueUses
      expire
      discountUses {
        userId
        date
        __typename
      }
      __typename
    }
  }
`;
export const deleteDiscount = /* GraphQL */ `
  mutation DeleteDiscount($input: DeleteDiscountInput) {
    deleteDiscount(input: $input) {
      id
      code
      type
      amount
      totalUses
      totalUniqueUses
      expire
      discountUses {
        userId
        date
        __typename
      }
      __typename
    }
  }
`;
export const createOnboarding = /* GraphQL */ `
  mutation CreateOnboarding($input: CreateOnboardingInput) {
    createOnboarding(input: $input) {
      id
      showTopSteps
      topText
      showBottomSteps
      bottomText
      type
      showLogo
      ctaText
      slideImage
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOnboarding = /* GraphQL */ `
  mutation UpdateOnboarding($input: UpdateOnboardingInput) {
    updateOnboarding(input: $input) {
      id
      showTopSteps
      topText
      showBottomSteps
      bottomText
      type
      showLogo
      ctaText
      slideImage
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOnboarding = /* GraphQL */ `
  mutation DeleteOnboarding($input: DeleteOnboardingInput) {
    deleteOnboarding(input: $input) {
      id
      showTopSteps
      topText
      showBottomSteps
      bottomText
      type
      showLogo
      ctaText
      slideImage
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: CreateNotificationInput) {
    createNotification(input: $input) {
      id
      type
      title
      body
      url
      user {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      notificationUserId
      notifSendApproach
      meta
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($input: DeleteNotificationInput) {
    deleteNotification(input: $input) {
      id
      type
      title
      body
      url
      user {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      notificationUserId
      notifSendApproach
      meta
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMembership = /* GraphQL */ `
  mutation CreateMembership($input: CreateMembershipInput) {
    createMembership(input: $input) {
      id
      name
      price
      dailyLimit
      monthlyLimit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMembership = /* GraphQL */ `
  mutation UpdateMembership($input: UpdateMembershipInput) {
    updateMembership(input: $input) {
      id
      name
      price
      dailyLimit
      monthlyLimit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMembership = /* GraphQL */ `
  mutation DeleteMembership($input: DeleteMembershipInput) {
    deleteMembership(input: $input) {
      id
      name
      price
      dailyLimit
      monthlyLimit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRegion = /* GraphQL */ `
  mutation CreateRegion($input: CreateRegionInput) {
    createRegion(input: $input) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRegion = /* GraphQL */ `
  mutation UpdateRegion($input: UpdateRegionInput) {
    updateRegion(input: $input) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRegion = /* GraphQL */ `
  mutation DeleteRegion($input: DeleteRegionInput) {
    deleteRegion(input: $input) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserNote = /* GraphQL */ `
  mutation CreateUserNote($input: CreateUserNoteInput) {
    createUserNote(input: $input) {
      id
      userId
      adminId
      timestamp
      adminName
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserNote = /* GraphQL */ `
  mutation UpdateUserNote($input: UpdateUserNoteInput) {
    updateUserNote(input: $input) {
      id
      userId
      adminId
      timestamp
      adminName
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserNote = /* GraphQL */ `
  mutation DeleteUserNote($input: DeleteUserNoteInput) {
    deleteUserNote(input: $input) {
      id
      userId
      adminId
      timestamp
      adminName
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserLog = /* GraphQL */ `
  mutation CreateUserLog($input: CreateUserLogInput) {
    createUserLog(input: $input) {
      id
      timestamp
      platform
      location
      ip
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile($input: BoUpdateUserProfileInput) {
    updateUserProfile(input: $input) {
      id
      accountType
      membership {
        id
        name
        price
        dailyLimit
        monthlyLimit
        createdAt
        updatedAt
        __typename
      }
      name
      lastName
      birthdate
      country
      address
      city
      postalCode
      email
      emailVerified
      phoneCode
      phoneNumber
      phoneNumberVerified
      kyc {
        status
        result
        caseId
        applicantId
        reason
        __typename
      }
      beneficiaries {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        bankDetails {
          name
          value
          __typename
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
        __typename
      }
      externalBankAccounts {
        name
        number
        iban
        swift
        sort
        __typename
      }
      poundsAccount {
        id
        name
        label
        type
        currency
        iban
        bban
        balance
        lastTx
        __typename
      }
      invitedBy {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      readNotifications
      readOnboardings
      amountsSent {
        daily
        monthly
        __typename
      }
      profileImage
      paymentMethods {
        last4
        scheme
        provider
        customerId
        expiryMonth
        id
        expiryYear
        type
        __typename
      }
      companyInfo {
        tradingName
        primaryHolderName
        industryType
        registeredAddress
        businessDescription
        annualTurnover
        stablishmentDate
        tradingStartDate
        transactionVolumen
        shareholders {
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          __typename
        }
        internationalActivity
        kybCompanyCode
        __typename
      }
      pushNotificationsConfig {
        token
        endpointArn
        __typename
      }
      smsMarketingConsent
      pushMarketingConsent
      emailMarketingConsent
      smsAllowed
      pushAllowed
      emailAllowed
      forbiddenCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      intercomProfileUrl
      accountStatus
      type
      passwordResetAt
      passwordResetBy
      customerId
      businessDetail
      shareholders
      SignUpAddressPersonalDetails
      SignUpFinancialDetails
      SignUpIndividualPersonalDetails
      transactionsInfo
      businessType
      author
      __typename
    }
  }
`;
export const adminResetPassword = /* GraphQL */ `
  mutation AdminResetPassword($userId: String) {
    adminResetPassword(userId: $userId) {
      passwordResetAt
      __typename
    }
  }
`;
export const sendSMSNotification = /* GraphQL */ `
  mutation SendSMSNotification($input: BackofficeSendSmsNotification) {
    sendSMSNotification(input: $input) {
      error
      messageId
      success
      __typename
    }
  }
`;
export const setUserWalletStatus = /* GraphQL */ `
  mutation SetUserWalletStatus($input: SetUserWalletStatusInput) {
    setUserWalletStatus(input: $input) {
      id
      currency
      balance
      history
      routingNumber
      accountNumber
      bankAddress
      bankName
      status
      type
      iban
      __typename
    }
  }
`;
export const createEmailTemplate = /* GraphQL */ `
  mutation CreateEmailTemplate($input: EmailTemplateInput) {
    createEmailTemplate(input: $input) {
      success
      __typename
    }
  }
`;
export const editEmailTemplate = /* GraphQL */ `
  mutation EditEmailTemplate($input: EmailTemplateInput) {
    editEmailTemplate(input: $input) {
      success
      __typename
    }
  }
`;
export const removeEmailTemplate = /* GraphQL */ `
  mutation RemoveEmailTemplate($templateName: String) {
    removeEmailTemplate(templateName: $templateName) {
      success
      error
      __typename
    }
  }
`;
export const sendEmailTemplate = /* GraphQL */ `
  mutation SendEmailTemplate($templateName: String, $sendTo: String, $htmlPart: String) {
    sendEmailTemplate(templateName: $templateName, sendTo: $sendTo, htmlPart: $htmlPart) {
      success
      error
      __typename
    }
  }
`;
export const renderEmailTemplate = /* GraphQL */ `
  mutation RenderEmailTemplate($templateName: String, $sendTo: String) {
    renderEmailTemplate(templateName: $templateName, sendTo: $sendTo) {
      renderedTemplate
      success
      __typename
    }
  }
`;
export const closeAccount = /* GraphQL */ `
  mutation CloseAccount($id: String!) {
    closeAccount(id: $id) {
      success
      error
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput) {
    createUser(input: $input) {
      success
      error
      __typename
    }
  }
`;
export const modifyUser = /* GraphQL */ `
  mutation ModifyUser($input: ModifyUserInput) {
    modifyUser(input: $input) {
      success
      __typename
    }
  }
`;
export const removeUser = /* GraphQL */ `
  mutation RemoveUser($userName: String) {
    removeUser(userName: $userName) {
      success
      __typename
    }
  }
`;
export const makeFXOrder = /* GraphQL */ `
  mutation MakeFXOrder($input: MakeFXOrderInput) {
    makeFXOrder(input: $input) {
      success
      error
      __typename
    }
  }
`;
export const makeHVIPayment = /* GraphQL */ `
  mutation MakeHVIPayment($input: MakeHVIPayment) {
    makeHVIPayment(input: $input) {
      success
      error
      __typename
    }
  }
`;
export const createPlansHeader = /* GraphQL */ `
  mutation CreatePlansHeader(
    $name: String!
    $description: String
    $price: String
    $parts: [InputPart]
  ) {
    createPlansHeader(name: $name, description: $description, price: $price, parts: $parts) {
      id
      name
      description
      price
      parts {
        name
        keyValues {
          key
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updatePlansHeader = /* GraphQL */ `
  mutation UpdatePlansHeader(
    $id: ID!
    $name: String
    $description: String
    $price: String
    $parts: [InputPart]
  ) {
    updatePlansHeader(
      id: $id
      name: $name
      description: $description
      price: $price
      parts: $parts
    ) {
      id
      name
      description
      price
      parts {
        name
        keyValues {
          key
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deletePlansHeader = /* GraphQL */ `
  mutation DeletePlansHeader($id: ID!) {
    deletePlansHeader(id: $id) {
      id
      name
      description
      price
      parts {
        name
        keyValues {
          key
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const newCloseAccount = /* GraphQL */ `
  mutation NewCloseAccount($input: CloseAccountInput) {
    newCloseAccount(input: $input) {
      message
      success
      __typename
    }
  }
`;
export const kybGenerateIncorporationCompany = /* GraphQL */ `
  mutation KybGenerateIncorporationCompany($companyId: String, $countryCode: String) {
    kybGenerateIncorporationCompany(companyId: $companyId, countryCode: $countryCode) {
      content
      filename
      contentType
      size
      __typename
    }
  }
`;
