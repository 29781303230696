import React, { useEffect, useState } from 'react';
import { useStyles } from './customer-filter-modal-by-type.hooks';

export const CustomerFilterModalByType = ({ listData }) => {
  const [dataObj, setDataObj] = useState(sessionStorage.getItem('account') || 'all');
  const classes = useStyles();
  const { fetchCustomerDataFromQuery } = listData;

  sessionStorage.setItem('account', "all");

  const handleToggle = type => {
    setDataObj(type);
    sessionStorage.setItem('account', type);
    localStorage.setItem('account', type);
    handleSubmit(type);
  };

  const handleSubmit = async input => {
    fetchCustomerDataFromQuery({
      query: '',
      key: 'name',
      account: input === 'all' ? '' : input,
    });
    sessionStorage.setItem('account', input);
    localStorage.setItem('account', input);
  };

  return (
    <div className={classes.customerFilterModalContainer}>
      <div>
        <div className={classes.actionBtnContainer}>
          <div className={classes.statusRow}>
            <div
              className={
                dataObj === 'all'
                  ? `${classes.createdActive} ${classes.defaultStatus}`
                  : classes.defaultStatus
              }
              onClick={() => handleToggle('all')}
            >
              All
            </div>
            <div
              className={
                dataObj === 'personal'
                  ? `${classes.createdActive} ${classes.defaultStatus}`
                  : classes.defaultStatus
              }
              onClick={() => handleToggle('personal')}
            >
              Personal
            </div>
            <div
              className={
                dataObj === 'company'
                  ? `${classes.createdActive} ${classes.defaultStatus}`
                  : classes.defaultStatus
              }
              onClick={() => handleToggle('company')}
            >
              Business
            </div>
            <div
              className={
                dataObj === 'freelance'
                  ? `${classes.createdActive} ${classes.defaultStatus}`
                  : classes.defaultStatus
              }
              onClick={() => handleToggle('freelance')}
            >
              Freelancer
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
