import React, { useEffect, useState } from 'react';
import { DottedLineIcon } from '../../icons';
import { graphqlApiDecorator } from 'decorators';
import { mutations, queries } from '../../../graphql';
import { Loader } from 'components/loader';
import { InfoInput } from './detail-components';
import { useStyles } from './otp-modal.hooks';
import { BeneficiaryBlank } from './detail-components/blank-templates';
import { GenericProfilePic, KixyLogoCircle } from './detail-components/icons';
import { Avatar } from '@mui/material';

export const SendOtp = ({ onClose, data, refetchProfileData, accountType }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [message, setMessage] = useState('');
  const [relatedShareHolders, setRelatedShareHolders] = useState([]);
  const [shareHolderId, setShareHolderId] = useState('');
  const [currentShareHolder, setCurrentShareHolder] = useState('');
  const [selectedShareHolder, setSelectedShareHolder] = useState(false);

  const handleClick = e => {
    e.preventDefault();
    onClose();
  };

  useEffect(() => {
    fetchRelatedShareHolders(data.id);
  }, []);

  useEffect(() => {
    setSelectedShareHolder(shareHolderId === currentShareHolder);
  }, [currentShareHolder]);

  const fetchRelatedShareHolders = async id => {
    const queryTitle = 'fetchRelatedShareHolders';
    setLoading(true);
    try {
      const result = await graphqlApiDecorator(queries, queryTitle, { id });
      if (result) {
        setRelatedShareHolders(result);
      }
    } catch (err) {
      console.log(err);
      setErrorMsg('Something went wrong');
      setMessage('');
    } finally {
      setLoading(false);
    }
  };

  const fetchOtpCode = async id => {
    const queryTitle = 'sendLtp';
    setLoading(true);
    try {
      const result = await graphqlApiDecorator(mutations, queryTitle, {
        userId: id,
        shareholderId: shareHolderId ?? null,
      });
      if (result.success) {
        setMessage('Code sent.');
        setErrorMsg('');
      }
      if (result.errors) {
        setErrorMsg('');
        setMessage(result.errors[0].message);
      }
    } catch (err) {
      console.log(err);
      setErrorMsg('Something went wrong');
      setMessage('');
    } finally {
      setLoading(false);
    }
  };

  const verifyOtpCode = async (id, code) => {
    const queryTitle = 'verifyLtp';
    setLoading(true);
    try {
      const result = await graphqlApiDecorator(mutations, queryTitle, { userId: id, code });
      if (result.success) {
        refetchProfileData();
        onClose();
      } else {
        setErrorMsg('Invalid Code');
        setMessage('');
      }
    } catch (err) {
      console.log(err);
      setErrorMsg('Something went wrong');
      setMessage('');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (accountType === 'company' && shareHolderId) {
      fetchOtpCode(data.id);
    } else {
      fetchOtpCode(data.id);
    }
  };

  const handleChangeOtp = value => {
    const regex = /^[0-9]{0,6}$/;

    if (regex.test(value)) {
      setOtpCode(value);

      if (value.length === 6) {
        verifyOtpCode(data.id, value);
      }
    }
  };

  return (
    <>
      <div className={classes.transactionFilterModalContainer}>
        <div className={classes.modalTitleContainer}>Send OTP</div>
        <form onSubmit={e => handleSubmit(e)}>
          <DottedLineIcon />

          {(accountType === 'company' || accountType === 'freelance') && (
            <div className={classes.otpRowContainer}>
              <p>Select the shareholder you want the code to be sent to.</p>
            </div>
          )}

          {(accountType === 'company' || accountType === 'freelance') && (
            <section className={classes.beneficiariesContainer}>
              {relatedShareHolders.length ? (
                relatedShareHolders
                  .filter(item => item.phoneNumber)
                  .map(item => {
                    let shareholderName = '';
                    if (item.name) shareholderName = `${item.name} ${item.lastName || ''}`;
                    if (!shareholderName && item.bankDetails) {
                      shareholderName = item.bankDetails.find(
                        el => el.name === 'beneficiary_company_name'
                      )?.value;
                    }

                    return (
                      <div
                        key={item.id}
                        className={
                          item.id === currentShareHolder
                            ? classes.beneficiaryRowSelected
                            : classes.beneficiaryRow
                        }
                        onClick={() => {
                          setShareHolderId(item.id);
                          setCurrentShareHolder(item.id);
                        }}
                      >
                        {item.profilePictureURL ? (
                          <div className={classes.profilePicContainer}>
                            <Avatar src={item.profilePictureURL} alt="profile pic" />
                            <div className={classes.smallIcon}>
                              <KixyLogoCircle />
                            </div>
                          </div>
                        ) : (
                          <GenericProfilePic />
                        )}
                        <div className={classes.beneficiaryText}>
                          <h3>{shareholderName}</h3>
                          <p className={classes.accountDetails}>{item.phoneNumber}</p>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className={classes.noBeneficiaries}>
                  {[...Array(3).keys()].map(e => (
                    <BeneficiaryBlank key={e} />
                  ))}
                </div>
              )}
            </section>
          )}

          <div className={classes.otpRowContainer}>
            <p>Enter the 6 digit code that was sent to user’s mobile number.</p>
          </div>

          <div className={classes.otpContainer}>
            <InfoInput
              type="text"
              value={otpCode}
              onChange={e => handleChangeOtp(e.target.value)}
              fullWidth
            />
          </div>

          <div className={classes.otpRowContainer}>
            {errorMsg && <p className={classes.textMsg}>{errorMsg}</p>}
            {message && <p className={classes.textMsg}>{message}</p>}
          </div>

          <div className={classes.btnBox}>
            <button type="submit" onClick={e => handleClick(e)} className={classes.cancelBtn}>
              <p className={classes.filterItLabel}>Close</p>
            </button>
            <button
              type="submit"
              className={`${
                (loading || (accountType === 'company' && !shareHolderId)) && classes.otpBtnDisabled
              } ${classes.otpBtn}`}
              disabled={loading || (accountType === 'company' && !shareHolderId)}
            >
              <p className={classes.filterItLabel}>
                {loading ? <Loader width="70px" /> : 'Send OTP'}
              </p>
            </button>
          </div>
          <div />
        </form>
      </div>
    </>
  );
};
