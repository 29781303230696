import React, { useEffect, useState } from 'react';
import { useStyles } from './risk-modal.hooks';
import { Loader } from 'components/loader';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Table,
  Paper,
  Tooltip,
  IconButton,
} from '@mui/material';
import { EditRiskRateModal } from './edit-risk-rate-modal';
import { RiskEditModal } from './risk-details-modal';
import { graphqlApiDecorator } from 'decorators';
import { queries } from '../../../../../graphql';
import { dateAndTime } from 'components/date-and-time';
import { RefreshIcon } from 'components/icons';
import { getCurrentUser } from 'services';

export const RiskModal = ({ onClose, userId }) => {
  const classes = useStyles();
  const [openCustomerFilterModal, setOpenCustomerFilterModal] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [riskId, setRiskId] = useState();
  const [editModal, setEditModal] = useState(false);
  const [currentRiskLevel, setCurrentRiskLevel] = useState('');
  const [userRoles, setUserRoles] = useState(null);

  useEffect(() => {
    fetchRisk();
    fetchCurrentRisk();
  }, []);

  useEffect(() => {
    const getSessionUser = async () => {
      const { success, data: userData } = await getCurrentUser();

      if (success) {
        setUserRoles(userData?.group);
      }
    };
    getSessionUser();
  }, []);

  const handleClick = id => {
    setRiskId(id);
    setShowDetails(true);
    setOpenCustomerFilterModal(false);
    setEditModal(false);
  };

  const handleOpenCustomerFilterModal = async () => {
    setOpenCustomerFilterModal(false);
    setEditModal(true);
  };

  const reFetchData = async () => {
    fetchRisk();
    fetchCurrentRisk();
  };

  const handleRiskRate = riskRate => {
    if (riskRate === 0) {
      return 'Low';
    }
    if (riskRate === 1) {
      return 'Medium';
    }
    if (riskRate === 2) {
      return 'High';
    }
    if (riskRate === 3) {
      return 'Very High';
    }
  };

  const fetchRisk = async () => {
    const queryTitle = 'getUserRisks';
    const input = { userId };
    setLoading(true);
    try {
      const result = await graphqlApiDecorator(queries, queryTitle, { input });
      setData(result.items);
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrentRisk = async () => {
    const queryTitle = 'getLatestUserRisks';
    try {
      const result = await graphqlApiDecorator(queries, queryTitle, { userId });
      if (result) {
        const currentRiskRate = result.filter(item => item.type === 'OVERALL');

        if (currentRiskRate.length > 0) {
          setCurrentRiskLevel(handleRiskRate(currentRiskRate[0].riskLevel));
        }
      }
    } catch (error) {}
  };

  const handleClodeModal = async () => {
    setData([]);
    await fetchRisk();
    reFetchData();
    setOpenCustomerFilterModal(true);
    setEditModal(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {openCustomerFilterModal && (
            <div className={classes.riskModalContainer}>
              <div className={classes.riskRateBtnContainer}>
                <div>
                  <p className={classes.currentRiskRate}>
                    Current Risk Rate: {currentRiskLevel || '--'}
                  </p>
                </div>
                <div className={classes.buttonGrop}>
                  {userRoles?.includes('admin', 'compliance') && (
                    <button
                      className={classes.riskRateBtn}
                      onClick={handleOpenCustomerFilterModal}
                      type="button"
                    >
                      See Details and Edit
                    </button>
                  )}
                  <div onClick={() => reFetchData()}>
                    <Tooltip title="Refresh Data">
                      <IconButton>
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>Row</TableCell>
                      <TableCell className={classes.tableCell}>Risk Rate</TableCell>
                      <TableCell className={classes.tableCell}>Date and time</TableCell>
                      <TableCell className={classes.tableCell}>Author</TableCell>
                      <TableCell className={classes.tableCell}>Comment</TableCell>
                      <TableCell className={classes.tableCell} style={{ paddingRight: '40px' }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.map((item, index) => (
                        <TableRow key={item.riskId} className={classes.tableRow}>
                          <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                          <TableCell className={classes.tableCell}>
                            {handleRiskRate(item.riskLevel)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {dateAndTime(item.updatedAt)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.updaterEmail ?? '--'}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.updaterComment ?? '--'}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <p
                              className={classes.seeDetailsBtn}
                              onClick={() => handleClick(item.riskId)}
                            >
                              See details
                            </p>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          {editModal && (
            <EditRiskRateModal
              onClose={() => {
                handleClodeModal();
              }}
              userId={userId}
            />
          )}
          {showDetails && (
            <RiskEditModal
              onClose={() => setOpenCustomerFilterModal(false)}
              id={riskId}
              userId={userId}
            />
          )}
        </>
      )}
    </>
  );
};
