import { TableCell, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  filterCustomerData,
  getFormattedDisplayDate,
  getFormattedDisplayTime,
  getKycInfo,
} from '../../../utils';
import { IntercomButton, SumsubButton } from '../../social-buttons';
import { useStyles } from './customers-table.hook';
import { formatDOB } from './utils/customers-table.utils';
import { CompanyHouseButton } from 'components/social-buttons/CompanyHouseButton';
import { getCompanyHouseInfo } from 'utils/companyHouse.utils';

/**
 * CustomersTable component that renders the userprofile entity data. Parent component is the
 * GenericTable component. This UI and data logic are separate to the usual GenericTableBody.
 * @param {Object[]} data - array of objects of type userProfile
 * @param {string} entity - the userProfile entity string
 * @param {boolean} loading - flag indicating if the data is loading
 */
export const CustomersTable = ({
  data,
  entity,
  isAllRecordsChecked,
  checkedRecords,
  setCheckedRecords,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerFilterObj = useSelector(state => state.customerFilterObj);

  const navigate = useNavigate();

  let tableBodyData = data ? [...data] : [];

  if (
    Object.keys(customerFilterObj).length > 0 &&
    Object.values(customerFilterObj).some(val => !!val)
  ) {
    tableBodyData = filterCustomerData(tableBodyData, customerFilterObj);
  }

  useEffect(() => {
    // clear out old search and filter

    dispatch({ type: 'SET_CUSTOMER_FILTER_OBJ', payload: {} });
  }, []);

  useEffect(() => {
    let payload = [];
    if (isAllRecordsChecked) {
      payload = tableBodyData;
    }

    if (payload.length === 0) return;
    if (!isAllRecordsChecked) {
      payload =
        data?.filter(record => {
          if (checkedRecords.length && checkedRecords.includes(record.id)) return true;

          return false;
        }) || [];
    }

    dispatch({ type: 'SET_CUSTOMER_TABLE_DATA_TO_EXPORT', payload });
  }, [checkedRecords, tableBodyData]);

  const customerDetailsPage = id => {
    navigate(`/${entity.toLowerCase()}/${id}`);
  };

  if (!data) return <p>No data</p>;

  return (
    <>
      {tableBodyData.map(record => {
        const { applicantId, kycLink } = getKycInfo(record?.kyc);
        const businessDetails = record.businessDetail;
        if (businessDetails) {
          const newBusinessDetails = JSON.parse(businessDetails);
          var registrationNumber = newBusinessDetails.registrationNumber;
        }
        const { companyLink } = getCompanyHouseInfo(registrationNumber);

        return (
          <TableRow
            key={record.id}
            className={classes.tableRow}
            sx={{
              cursor: 'pointer',
              '& .MuiTableCell-root': {
                borderBottom: '1px solid #e6e6e6',
                color: '#19365e',
              },
              '&:hover .MuiTableCell-root:first-of-type': {
                borderRadius: '8px 0 0 8px',
              },
              '&:hover .MuiTableCell-root:last-of-type': {
                borderRadius: '0 8px 8px 0',
              },
              background: 'white',
            }}
            onClick={() => {
              customerDetailsPage(record.id);
            }}
          >
            <TableCell className={classes.nameTableData}>
              <div className={classes.name}>
                <input
                  type="checkbox"
                  checked={checkedRecords.includes(record.id) || isAllRecordsChecked}
                  onClick={event => event.stopPropagation()}
                  onChange={() => {
                    setCheckedRecords(prev => {
                      let newRecords = [...prev];
                      if (newRecords.includes(record.id)) {
                        newRecords = newRecords.filter(item => item !== record.id);
                      } else {
                        newRecords.push(record.id);
                      }

                      return newRecords;
                    });
                  }}
                />
                <p
                  style={{ fontSize: '14px', fontWeight: '500' }}
                >{`${record.name} ${record.lastName || ""}`}</p>
              </div>
              <div className={classes.intercomRow}>
                <IntercomButton
                  profileUrl={record?.intercomProfileUrl}
                  accountStatus={record?.accountStatus}
                />
                <div className={classes.nameSmallDetails}>
                  <div className={classes.internalNumber}>
                    <p>Internal Number (IN): </p>
                    <p>{record.customerId}</p>
                  </div>
                  <p style={{ fontSize: '14px' }}>{record.country}</p>
                </div>
              </div>
              <div className={classes.sumSubRow}>
                <SumsubButton profileUrl={kycLink} disabled={!applicantId} />
                <div className={classes.createdDetails}>
                  <p>Created: </p>
                  <p>{`${getFormattedDisplayDate(record.createdAt)} ${getFormattedDisplayTime(
                    record.createdAt
                  )}`}</p>
                </div>
              </div>
              {record.accountType === 'company' && (
                <div className={classes.companyHouseRow}>
                  <CompanyHouseButton profileUrl={companyLink} />
                  <div className={classes.createdDetails}>
                    <p>View on Companies house</p>
                  </div>
                </div>
              )}
            </TableCell>
            {record.accountStatus === 'active' && (
              <TableCell>
                <p style={{ color: '#1fe3ac', fontWeight: 500, fontSize: '14px' }}>Active</p>
              </TableCell>
            )}
            {record.accountStatus === 'created' && (
              <TableCell>
                <p style={{ color: '#0cc5ff', fontWeight: '500', fontSize: '14px' }}>Created</p>
              </TableCell>
            )}
            {record.accountStatus === 'account_verification_failed' && (
              <TableCell>
                <p style={{ color: '#fc3333', fontWeight: '500', fontSize: '14px' }}>Failed</p>
              </TableCell>
            )}
            {record.accountStatus === 'suspended' && (
              <TableCell>
                <p style={{ color: '#a5a6ae', fontWeight: '500', fontSize: '14px' }}>Suspended</p>
              </TableCell>
            )}
            {record.accountStatus === 'deactivated' && (
              <TableCell>
                <p style={{ color: '#a5a6ae', fontWeight: '500', fontSize: '14px' }}>Deactivated</p>
              </TableCell>
            )}
            {record.accountStatus === 'closed' && (
              <TableCell>
                <p style={{ color: '#fc3333', fontWeight: '500', fontSize: '14px' }}>Closed</p>
              </TableCell>
            )}
            <TableCell>{record.accountType || ''}</TableCell>
            {/* <TableCell>{formatAccountNo(record.poundsAccount?.iban) || ''}</TableCell> */}
            <TableCell>{`${record.phoneCode ?? ''} ${record.phoneNumber ?? ''}`}</TableCell>
            <TableCell>{record.birthdate ? formatDOB(record.birthdate) : ''}</TableCell>
            <TableCell>{record.accountType !== 'company' ? record.email : ''}</TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
