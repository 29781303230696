import HttpService from '../restBase';

class KybServiceProvider extends HttpService {
  constructor() {
    super({ suffix: 'user/v1/admin/user/company/kyb' });
  }

  async updateCompanyStatus(id, accountStatus) {
    const response = await this.httpService.put('', {
      id,
      accountStatus,
    });
    return response;
  }
}

const KybService = new KybServiceProvider();

export default KybService;
