// Limit the number of fields as time out error occurs
export const getUserTransactions = /* GraphQL */ `
  query GetUserTransactions($id: ID!) {
    getUserTransactions(id: $id) {
      id
      type
      createdAt
      fromCurrency
      toCurrency
      sendingAmount
      receivingAmount
      userIdFrom
      userIdTo
      reference
      purpose
      adminAttachments
      failureReason
      status
      runningBalanceFrom
      runningBalanceTo
      externalUserFrom {
        name
        lastName
      }
      beneficiary {
        id
        name
        lastName
      }
      userFrom {
        id
        name
        lastName
      }
      userTo {
        id
        name
        lastName
      }
      creditorAccount {
        name
        ownerName
        transactionOwnerName
      }
      debtorAccount {
        name
        ownerName
        transactionOwnerName
      }
    }
  }
`;
