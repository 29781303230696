import { useEffect, useState } from 'react';

import { getEntity } from '../services';
import { getRelatedEntityFieldName } from '../utils';

const getFieldValue = (field, dataField) => {
  if (field.type === 'subObjectList') {
    return dataField || [];
  }

  if (field.type !== 'listFree' && Array.isArray(dataField)) {
    return dataField.map(df => df?.id).filter(Boolean);
  }

  if (dataField && typeof dataField === 'object' && dataField.id) {
    return dataField.id;
  }

  return dataField;
};

const getFieldName = (entity, data, field) => {
  const fieldName = field.name;

  if (field.type === 'subObjectList') {
    return fieldName;
  }

  if (field.type !== 'listFree' && Array.isArray(data[fieldName])) {
    return getRelatedEntityFieldName(entity, fieldName);
  }

  if (data[fieldName] && typeof data[fieldName] === 'object' && data[fieldName].id) {
    return getRelatedEntityFieldName(entity, fieldName);
  }

  return fieldName;
};

export const useGraphGet = (entity, id, fields) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [data, setData] = useState(null);
  const [rawData, setRawData] = useState({});

  useEffect(() => {
    fetchEntity();
  }, [entity, fields, id]);

  const fetchEntity = async () => {
    const { success, msg, data } = await getEntity(entity, id);

    if (success) {
      setRawData(data);
      const formData = fields.map(f => {
        const fieldName = getFieldName(entity, data, f);
        const fieldValue = getFieldValue(f, data[f.name]);
        const fieldValueParsed =
          Array.isArray(fieldValue) && fieldValue.length === 0 ? null : fieldValue;

        return { [fieldName]: fieldValueParsed };
      });
      setData(formData);
    } else {
      setError(msg);
    }
    setLoading(false);
  };

  const refetch = async () => {
    setLoading(true);
    await fetchEntity();
  };

  return { loading, error, data, rawData, refetch };
};
