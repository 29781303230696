/* eslint-disable no-unused-vars */
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

import { graphqlApiDecorator } from '../../../../decorators';
import { mutations, queries } from '../../../../graphql';
import { getCurrencySymbol } from '../../../../utils';
import { CopyToClipBoardIcon } from '../../../icons';
import { currencies, currencyColors } from '../constants';
import { ReturnCountryIcon } from '../icons/countries/ReturnCountryIcon';
import { BlankWallet } from './blank-templates';
import { NextIcon, PrevIcon, SideRectangle } from './icons';
import { SavedModal, WarningModal } from './modals';
import { useStyles } from './wallets.hooks';

/**
 * Wallets sub-component in the CustomerDetailPage component. Displays all the wallets the user has
 * with the feature to disable/enable multi-currency wallets. At time of writing, GBP wallet cannot be
 * disabled as it has a different data structure compared to multi-currency wallets.
 * If GBP wallet needs to be disabled, recommend suspending the account to prevent customer from using the app. Go to Account
 * status and click on 'suspended' and save to disable customer usage of app.
 * @param {object} data the user profile data of type userProfile
 */
export const Wallets = ({ data, setId }) => {
  const [walletsData, setWalletsData] = useState([]);
  const [selectedWallets, setSelectedWallets] = useState([]);
  const [selectAllWallets, setSelectAllWallets] = useState(false);
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [header, setHeader] = useState('');
  const [content, setContent] = useState('');
  const classes = useStyles();

  // This endPoint can enable/disable wallet status except for GBP
  const mutationTitle = 'setUserWalletStatus';

  const fetchUserWallets = async id => {
    setId(id);
    const getUserWallets = 'getUserWallets';
    const getCurrencyIBAN = 'getCurrencyIBAN';
    try {
      const result = await graphqlApiDecorator(queries, getUserWallets, { id });
      // the user poundsAccount is not in getUswerWallets but in the getUserProfile
      const { balance, iban, type } = data.poundsAccount || {};

      const currencyIBANResult = await graphqlApiDecorator(queries, getCurrencyIBAN, {
        id: `${id}-multiCurrency`,
      });

      let multiCurrencyIBAN = '';

      if (currencyIBANResult) {
        multiCurrencyIBAN = currencyIBANResult?.iban;
      }

      setWalletsData(result.sort((a, b) => b.balance - a.balance));
    } catch (error) {
      setContent(error.message);
      setShowWarningModal(true);
    }
  };

  const settings = {
    centerPadding: '60px',
    slidesToShow: walletsData.length > 3 ? 3 : walletsData.length,
    swipeToSlide: true,
    nextArrow: <NextIcon />,
    prevArrow: <PrevIcon />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: false,
        },
      },
    ],
  };

  useEffect(() => {
    fetchUserWallets(data.id);
  }, [data]);

  useEffect(() => {
    if (selectAllWallets) {
      setSelectedWallets(() => {
        const allWallets = [];
        walletsData.forEach(wallet => {
          if (wallet.currency !== 'GBP') allWallets.push(wallet.currency);
        });

        return allWallets;
      });
    } else {
      setSelectedWallets([]);
    }
  }, [selectAllWallets]);

  const walletClicked = walletCurrency => {
    if (selectedWallets.includes(walletCurrency)) {
      // wallet is already selected so need to remove
      setSelectedWallets(prev => {
        const newSelectedWallets = [...prev];

        return newSelectedWallets.filter(item => item !== walletCurrency);
      });
    }

    if (!selectedWallets.includes(walletCurrency)) {
      setSelectedWallets(prev => {
        const newSelectedWallets = [...prev];
        newSelectedWallets.push(walletCurrency);

        return newSelectedWallets;
      });
    }
  };

  const disableWallets = async () => {
    if (!selectedWallets.length) return;

    try {
      await Promise.all(
        selectedWallets.map(async wallet => {
          const input = {
            id: data.id,
            currency: wallet,
            newStatus: 'disabled',
          };

          const result = await graphqlApiDecorator(mutations, mutationTitle, { input });

          return result;
        })
      );

      let walletsDisabled = '';

      selectedWallets.forEach(wallet => {
        walletsDisabled += `${wallet}, `;
      });

      walletsDisabled = walletsDisabled.slice(0, walletsDisabled.length - 2);
      setContent(`Wallets ${walletsDisabled} are disabled`);
      setHeader('Saved');
      setShowSavedModal(true);
    } catch (error) {
      setContent(`Error: ${error.message}`);
      setShowWarningModal(true);
    }
  };

  const enableWallets = async () => {
    if (!selectedWallets.length) return;

    try {
      await Promise.all(
        selectedWallets.map(async wallet => {
          const input = {
            id: data.id,
            currency: wallet,
            newStatus: 'active',
          };

          const result = await graphqlApiDecorator(mutations, mutationTitle, { input });

          return result;
        })
      );

      let walletsEnabled = '';

      selectedWallets.forEach(wallet => {
        walletsEnabled += `${wallet}, `;
      });

      walletsEnabled = walletsEnabled.slice(0, walletsEnabled.length - 2);
      setContent(`Wallets ${walletsEnabled} are enabled`);
      setHeader('Saved');
      setShowSavedModal(true);
    } catch (error) {
      setContent(`Error: ${error.message}`);
      setShowWarningModal(true);
    }
  };

  const getSortCodeFromIban = iban => {
    if (iban) return iban.slice(8, 14);

    return '';
  };

  const getAccountNoFromIban = iban => {
    if (iban) return iban.slice(14);

    return '';
  };

  const closeSavedModal = () => {
    setShowSavedModal(false);
    fetchUserWallets(data.id);
  };

  const closeWarningModal = () => {
    setShowWarningModal(false);
  };

  const alternativeCopyToClipboard = text => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  };
  const copyToClipBoard = (event, text) => {
    event.stopPropagation();
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(text);
    } else {
      alternativeCopyToClipboard(text);
    }
  };

  const renderWallet = wallet => (
    <>
      <div
        key={wallet.currency}
        className={
          selectedWallets?.includes(wallet.currency)
            ? `${classes.walletTemplate} ${classes.walletSelected}`
            : classes.walletTemplate
        }
        onClick={() => {
          if (wallet.currency !== 'GBP') walletClicked(wallet.currency);
        }}
      >
        <div
          className={
            wallet.status === 'active' || wallet.status === null || wallet.type === 'personal'
              ? classes.walletBackground
              : `${classes.walletBackground} ${classes.disabledWallet}`
          }
          style={{
            backgroundColor:
              wallet.status === 'active' || wallet.status === null || wallet.type === 'personal'
                ? currencyColors[wallet.currency]
                : '#f2f2f4',
          }}
        >
          <header>{`${getCurrencySymbol(
            wallet.currency
          )} ${wallet?.balance?.toLocaleString()}`}</header>
          <p>{`${currencies[wallet.currency]} (${wallet.currency})`}</p>
          <div className={classes.accountNumberRow}>
            {wallet.accountNumber && <p>{wallet.accountNumber}</p>}
            {(wallet.type === 'virtual' || wallet.type === null) && <p>Virtual Account</p>}
            {wallet.type === 'personal' && (
              <>
                <p>{getAccountNoFromIban(wallet.iban)}</p>
                <p>{getSortCodeFromIban(wallet.iban)}</p>
              </>
            )}
            <div
              className={
                wallet.status !== 'active' && wallet.type !== 'personal' && wallet.status !== null
                  ? classes.disabledCountry
                  : classes.enabledCountry
              }
            >
              <ReturnCountryIcon country={wallet.currency} width="20px" height="20px" />
            </div>
          </div>
        </div>
        {wallet.iban && (
          <div className={classes.ibanRow}>
            <p>{`IBAN: ${wallet.iban}`}</p>
            <Tooltip title="Copy to clipboard">
              <div className={classes.clipBoardIcon} onClick={e => copyToClipBoard(e, wallet.iban)}>
                <CopyToClipBoardIcon />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div>
      <div className={classes.topRow}>
        <div className={classes.label}>
          <SideRectangle />
          <header>Wallets</header>
        </div>
        {/* <div className={classes.walletDisableEnable}>
          <input
            type="checkbox"
            name="selectAllWallets"
            value={selectAllWallets}
            onChange={() => setSelectAllWallets(!selectAllWallets)}
          />
          <label htmlFor="selectAllWallets">Select All</label>
          {walletsData.find(wallet => wallet.status === 'active' || wallet.type === 'personal') && (
            <div className={classes.disableWalletBtn} onClick={disableWallets}>
              {selectedWallets.length <= 1 && <p>Disable Wallet</p>}
              {selectedWallets.length > 1 && <p>Disable Wallets</p>}
            </div>
          )}
          {walletsData.find(wallet => wallet.status === 'disabled') && (
            <div className={classes.disableWalletBtn} onClick={enableWallets}>
              {selectedWallets.length <= 1 && <p>Enable Wallet</p>}
              {selectedWallets.length > 1 && <p>Enable Wallets</p>}
            </div>
          )}
        </div> */}
      </div>

      <div className={classes.carouselContainer}>
        {walletsData.length > 1 && (
          <Slider {...settings}>{walletsData.map(wallet => renderWallet(wallet))}</Slider>
        )}
        {walletsData.length === 1 && (
          <div className={classes.singleWalletDisplay}>
            {renderWallet(walletsData[0])}
            <BlankWallet />
            <BlankWallet />
          </div>
        )}
        {walletsData.length === 0 && (
          <div className={classes.singleWalletDisplay}>
            <BlankWallet />
            <BlankWallet />
            <BlankWallet />
          </div>
        )}
      </div>
      <SavedModal
        open={showSavedModal}
        onClose={closeSavedModal}
        header={header}
        content={content}
      />
      <WarningModal
        open={showWarningModal}
        onClose={closeWarningModal}
        confirm={closeWarningModal}
        message={content}
      />
    </div>
  );
};
