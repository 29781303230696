import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  otpContainer: {
    fontSize: '12px',
    margin: '16px 16px 0px 16px',
    '& input[type="text"]': {
      height: '32px',
      border: '1px solid #f2f2f4',
      padding: '8px',
      borderRadius: '8px',
      color: theme.palette.icons.nav,
      fontWeight: 'bold',
    },
    '& input[type="text"]:focus, textarea': {
      outline: 'none !important',
    },
    '& > section': {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      alignItems: 'center',
    },
  },
  dashedLine: {
    borderBottom: '1px solid #a5a6ae',
    borderBottomStyle: 'dashed',
    marginTop: '40px',
    marginBottom: '33px',
  },
  transactionFilterModalContainer: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '480px',
    height: 'auto',
    borderRadius: '15px',
    zIndex: 5,
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    padding: '21px',
  },
  modalTitleContainer: {
    textAlign: 'center',
    fontSize: '20px',
    fontFamily: theme.typography.default,
    fontWeight: 'bold',
    marginBottom: '1.5em',
  },
  otpRowContainer: {
    fontSize: '13px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '1em',
    alignItems: 'center',
    margin: '15px 0',
    '& p': {
      color: '#a5a6ae',
    },
    '& div': {
      fontWeight: 'bold',
    },
  },
  textMsg: {
    color: 'red !important',
  },
  disableResetBtn: {
    width: '120px',
    height: '30px',
    borderRadius: '16px',
    backgroundColor: 'lightgray',
    fontWeight: '500',
    fontSize: '13px',
    textAlign: 'center',
    paddingTop: '8px',
    cursor: 'no-drop',
    marginLeft: '30px',
  },
  inputContainer: {
    border: `1px solid ${theme.palette.transactions.background}`,
    borderRadius: '14px',
    width: '120px',
    height: '28px',
    fontSize: '14px',
    padding: '5px',
    color: theme.palette.icons.nav,
    fontFamily: theme.typography.default,
    textAlign: 'center',
  },
  otpBtn: {
    backgroundColor: theme.palette.icons.nav,
    width: '432px',
    height: '40px',
    borderRadius: '8px',
    color: 'white',
    border: 'none',
    position: 'relative',
    cursor: 'pointer',
    marginTop: '20px',
  },
  cancelBtn: {
    backgroundColor: theme.palette.fail.color,
    width: '432px',
    height: '40px',
    borderRadius: '8px',
    color: 'white',
    border: 'none',
    position: 'relative',
    cursor: 'pointer',
    marginTop: '20px',
  },
  btnBox: {
    display: 'flex',
    gap: '20px',
    marginTop: '20px',
  },
  otpBtnDisabled: {
    backgroundColor: '#d9e1e7',
    cursor: 'auto',
  },
  beneficiariesContainer: {
    padding: '16px',
    fontSize: '14px',
    height: '250px',
    marginTop: '16px',
    overflowY: 'auto',
  },
  beneficiaryRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px',
    gap: '8px',
    borderRadius: '8px',
    marginBottom: '24px',
    cursor: 'pointer',
  },
  beneficiaryRowSelected: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: '#c6ffef',
    gap: '8px',
    borderRadius: '8px',
    marginBottom: '24px',
    cursor: 'pointer',
  },
  beneficiaryText: {
    marginLeft: '8px',
    '& p, strong': {
      color: '#a5a6ae',
    },
    '& h3': {
      fontWeight: '500',
    },
  },
  accountDetails: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '8px',
  },
  profilePicContainer: {
    position: 'relative',
  },
  smallIcon: {
    position: 'absolute',
    right: '-10px',
    top: '24px',
  },
  noBeneficiaries: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  }
}));
