/* eslint-disable react/jsx-no-useless-fragment */
import SortIcon from '@mui/icons-material/Sort';
import { Box, Table, TableBody, TableRow } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { entitiesEnum, transactionSortEnum } from '../../../../constants';
import { graphqlApiDecorator } from '../../../../decorators';
import { modifiedQueries } from '../../../../graphql';
import {
  filterTransactionData,
  filterTransactionDataByName,
  sortTransactionData,
} from '../../../../utils';
import {
  TransactionFilterModal,
  TransactionSortModal,
} from '../../../../views/generic-list/components/modal';
import { SearchBar } from '../../../../views/generic-list/components/search-bar';
import { FilterIcon } from '../../../icons';
import { TableExport } from '../../../table-export';
import { TransactionFilterSummary } from '../../components/generic-table-body/components';
import { TransactionRow } from '../../components/generic-table-body/components/transaction-row';
import { TransactionRowBlank } from './blank-templates/transaction-row-blank';
import { SideRectangle } from './icons';
import { useStyles } from './transactions.hooks';

/**
 * Transactions component in Customer Detail Page to show transactions related to customer
 * by calling the 'getUserTransactions' graphQL query. A modified query is used to speed up the
 * graphQL query as only certain attributes are needed.
 * @param {object} data - this contains the userProfile data of the customer
 * Features include searching, exporting, filtering and sorting the transaction data.
 */

export const Transactions = ({ data, entity = entitiesEnum.TRANSACTION }) => {
  const [transactionsList, setTransactionsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterModal, setFilterModal] = useState(false);
  const [sortModal, setSortModal] = useState(false);
  const userTransactionFilterObj = useSelector(state => state.userTransactionFilterObj);
  const classes = useStyles();
  const userTransactionSearchQuery = useSelector(state => state.userTransactionSearchQuery);
  const userTransactionSort = useSelector(state => state.userTransactionSort);
  const dispatch = useDispatch();
  const USERTRANSACTIONS = 'userTransactions';
  const isFiltered = obj => Object.keys(obj).length > 0;
  const navigate = useNavigate();

  let tableBodyData = transactionsList?.length ? [...transactionsList] : [];
  const fetchUserTransactions = async id => {
    try {
      const queryTitle = 'getUserTransactions';
      const result = await graphqlApiDecorator(modifiedQueries, queryTitle, { id });
      if (result) {
        setLoading(false);
        if (entity === entitiesEnum.USERPROFILE) {
          const resultInDateOrder = sortTransactionData(
            [...result],
            transactionSortEnum.DATENEWEST
          );

          let initialBalance = data?.poundsAccount?.balance || 0;
          let balanceBefore = initialBalance;

          const resultWithSnapshotBalance = resultInDateOrder.map(transaction => {
            if (transaction.status !== 'success') {
              return { ...transaction, totalBalance: Math.round(initialBalance * 100) / 100 };
            }
            if (transaction.type === 'add_money') initialBalance -= transaction.sendingAmount;
            if (transaction.type === 'send_money') {
              if (transaction.userTo?.id === id) {
                initialBalance -= transaction.sendingAmount;
              } else {
                initialBalance += transaction.sendingAmount;
              }
            }
            if (transaction.type === 'convert' && transaction.fromCurrency === 'GBP')
              initialBalance += transaction.sendingAmount;
            if (transaction.type === 'convert' && transaction.toCurrency === 'GBP')
              initialBalance -= transaction.receivingAmount;

            const updatedTransaction = { ...transaction };

            updatedTransaction.totalBalance = Math.round(balanceBefore * 100) / 100;
            balanceBefore = initialBalance;

            return updatedTransaction;
          });

          const updatedTransactionWithSnapshotBalance = sortTransactionData(
            resultWithSnapshotBalance,
            transactionSortEnum.DATENEWEST
          );
          setTransactionsList(updatedTransactionWithSnapshotBalance);
        }
      } else {
        setTransactionsList(result);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchUserTransactions(data.id);
    // clear out old search and filters
    dispatch({ type: 'SET_USER_TRANSACTION_SEARCH_QUERY', payload: '' });
    dispatch({ type: 'SET_USER_TRANSACTION_FILTER_OBJ', payload: {} });
  }, [data]);

  useEffect(() => {
    dispatch({ type: 'SET_USER_TRANSACTIONS_DATA_TO_EXPORT', payload: tableBodyData });
  }, [tableBodyData]);

  const handleUserTransactionSearchChange = event => {
    dispatch({ type: 'SET_USER_TRANSACTION_SEARCH_QUERY', payload: event.target.value });
  };

  const handleOpenFilterModal = () => setFilterModal(true);
  const handleCloseFilterModal = () => setFilterModal(false);
  const handleOpenSortModal = () => setSortModal(true);

  if (userTransactionSearchQuery !== '') {
    tableBodyData = filterTransactionDataByName(userTransactionSearchQuery, tableBodyData);
  }

  if (Object.keys(userTransactionFilterObj).length) {
    tableBodyData = filterTransactionData(tableBodyData, userTransactionFilterObj, true);
  }

  if (userTransactionSort) {
    tableBodyData = sortTransactionData(tableBodyData, userTransactionSort);
  }

  const onClickedRow = data => {
    navigate(`/${entitiesEnum.TRANSACTION.toLowerCase()}/${data.id}`);
  };

  return (
    <main>
      <section className={classes.label}>
        <aside>
          <SideRectangle />
          <header>Transactions</header>
        </aside>
        <aside>
          <SearchBar
            searchQuery={userTransactionSearchQuery}
            changeQuery={handleUserTransactionSearchChange}
          />
          <TableExport entity={USERTRANSACTIONS} name={`${data.name} ${data.lastName}`} />
          <div className={classes.filterIcon} onClick={handleOpenFilterModal}>
            <Tooltip title="Filter Results">
              <IconButton>
                <FilterIcon active={isFiltered(userTransactionFilterObj)} />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.sortIcon} onClick={handleOpenSortModal}>
            <Tooltip title="Sort Results">
              <IconButton>
                <SortIcon sx={{ fontSize: '28px', color: '#19365e' }} />
              </IconButton>
            </Tooltip>
          </div>
        </aside>
      </section>
      <div className={classes.filterSummary}>
        <TransactionFilterSummary entity={USERTRANSACTIONS} />
      </div>
      <section className={classes.transactionsContainer}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '5%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableBody>
              <>
                {tableBodyData.length ? (
                  tableBodyData.map(record => (
                    <TableRow key={record.id} className={classes.transactionsTableRow}>
                      <TransactionRow
                        currentUserId={data.id}
                        rowData={record}
                        onClickedRow={onClickedRow}
                        entity={entitiesEnum.USERPROFILE}
                      />
                    </TableRow>
                  ))
                ) : (
                  <div className={classes.transactionBlank}>
                    <TransactionRowBlank />
                    <TransactionRowBlank />
                    <TransactionRowBlank />
                  </div>
                )}
              </>
            </TableBody>
          </Table>
        )}
        <Modal open={filterModal} onClose={handleCloseFilterModal}>
          <Box>
            <TransactionFilterModal onClose={handleCloseFilterModal} entity={USERTRANSACTIONS} />
          </Box>
        </Modal>
        <Modal open={sortModal} onClose={() => setSortModal(false)}>
          <Box>
            <TransactionSortModal
              entity={entitiesEnum.USERPROFILE}
              onClose={() => setSortModal(false)}
            />
          </Box>
        </Modal>
      </section>
    </main>
  );
};
