import axios from 'axios';
import { get } from 'lodash';
import { readToken } from 'utils/token';

class RestBaseService {
  constructor({ suffix, baseURL = process.env.REACT_APP_STATEMENT_URL }) {
    this.httpService = axios.create({
      baseURL: `${baseURL}${suffix ? `/${suffix}` : ''}`,
      timeout: 60000,
      validateStatus(status) {
        return (status >= 200 && status < 300) || status === 490 || status === 491;
      },
      headers: {
        platform: 'WEB',
        'Content-Type': 'application/json',
      },
    });

    this.requestInterceptors();
    this.responseInterceptors();
  }

  responseInterceptors() {
    this.httpService.interceptors.response.use(
      response => {
        const { data } = response;
        if (data) {
          return get(response, 'data');
        } else {
          return response;
        }
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  requestInterceptors() {
    this.httpService.interceptors.request.use(
      async config => {
        if (!config.headers.Authorization || config.headers.Authorization === 'Bearer undefined') {
          const token = await readToken();
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        }
        return config;
      },
      error => Promise.reject(error)
    );
  }
}

export default RestBaseService;
