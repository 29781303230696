import { getCurrentUser } from 'services';

export const readToken = async () => {
  try {
    const data = await getCurrentUser();
    return data.accessToken;
  } catch (error) {
    console.error('Error getting the token', error);
    return null;
  }
};
