/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserRisks = /* GraphQL */ `
  query GetUserRisks($input: GetUserRisksInput!) {
    getUserRisks(input: $input) {
      items {
        type
        itemValue
        riskLevel
        updatedAt
        updaterEmail
        updaterComment
        riskId
        freezeUntil
        __typename
      }
      from
      size
      total
      __typename
    }
  }
`;
export const getLatestUserRisks = /* GraphQL */ `
  query GetLatestUserRisks($userId: ID!) {
    getLatestUserRisks(userId: $userId) {
      type
      itemValue
      riskLevel
      updatedAt
      updaterEmail
      updaterComment
      riskId
      freezeUntil
      __typename
    }
  }
`;
export const getUserLimitWaiver = /* GraphQL */ `
  query GetUserLimitWaiver($userId: ID!) {
    getUserLimitWaiver(userId: $userId)
  }
`;
export const getUserLimits = /* GraphQL */ `
  query GetUserLimits($userId: ID!) {
    getUserLimits(userId: $userId) {
      monthlyLimit
      dailyLimit
      perTransactionLimit
      __typename
    }
  }
`;
export const getMCCYTransaction = /* GraphQL */ `
  query GetMCCYTransaction($endToEndId: String!) {
    getMCCYTransaction(endToEndId: $endToEndId)
  }
`;
export const getApplicationsStatus = /* GraphQL */ `
  query GetApplicationsStatus {
    getApplicationsStatus {
      totalApplications
      totalNumberOfRejects
      totalNumberOfAccept
      totalNumberOfPending
      numberOfApplicationThisWeek
      numberOfAcceptsThisWeek
      numberOfRejectsThisWeek
      numberOfPendingThisWeek
      numberOfPersonalAccounts
      numberOfBusinessAccounts
      numberOfHighRiskAccount
      numberOfMidRiskAccount
      numberOfLowRiskAccount
      numberPEPS
      numberOfFraudulentApplications
      __typename
    }
  }
`;
export const listTransferFees = /* GraphQL */ `
  query ListTransferFees {
    listTransferFees {
      items {
        paymentMethod
        fee
        __typename
      }
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser {
    getUser {
      id
      accountType
      membership {
        id
        name
        price
        dailyLimit
        monthlyLimit
        createdAt
        updatedAt
        __typename
      }
      name
      lastName
      birthdate
      country
      address
      city
      postalCode
      email
      emailVerified
      phoneCode
      phoneNumber
      phoneNumberVerified
      kyc {
        status
        result
        caseId
        applicantId
        reason
        __typename
      }
      beneficiaries {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        bankDetails {
          name
          value
          __typename
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
        __typename
      }
      externalBankAccounts {
        name
        number
        iban
        swift
        sort
        __typename
      }
      poundsAccount {
        id
        name
        label
        type
        currency
        iban
        bban
        balance
        lastTx
        __typename
      }
      invitedBy {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      readNotifications
      readOnboardings
      amountsSent {
        daily
        monthly
        __typename
      }
      profileImage
      paymentMethods {
        last4
        scheme
        provider
        customerId
        expiryMonth
        id
        expiryYear
        type
        __typename
      }
      companyInfo {
        tradingName
        primaryHolderName
        industryType
        registeredAddress
        businessDescription
        annualTurnover
        stablishmentDate
        tradingStartDate
        transactionVolumen
        shareholders {
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          __typename
        }
        internationalActivity
        kybCompanyCode
        __typename
      }
      pushNotificationsConfig {
        token
        endpointArn
        __typename
      }
      smsMarketingConsent
      pushMarketingConsent
      emailMarketingConsent
      smsAllowed
      pushAllowed
      emailAllowed
      forbiddenCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      intercomProfileUrl
      accountStatus
      type
      passwordResetAt
      passwordResetBy
      customerId
      businessDetail
      shareholders
      SignUpAddressPersonalDetails
      SignUpFinancialDetails
      SignUpIndividualPersonalDetails
      transactionsInfo
      businessType
      author
      __typename
    }
  }
`;
export const getBeneficiary = /* GraphQL */ `
  query GetBeneficiary($id: ID!) {
    getBeneficiary(id: $id) {
      id
      status
      userId
      accountType
      name
      lastName
      country {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
        __typename
      }
      address
      city
      postalCode
      email
      phoneCode
      phoneNumber
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      bankDetails {
        name
        value
        __typename
      }
      userProfileId
      usageLevel
      skipInternalCheck
      currencyRequirementType
      beneficiaryCountryId
      beneficiaryCurrencyId
      __typename
    }
  }
`;
export const getUserBeneficiaries = /* GraphQL */ `
  query GetUserBeneficiaries($id: ID!) {
    getUserBeneficiaries(id: $id) {
      items {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        bankDetails {
          name
          value
          __typename
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserActivity = /* GraphQL */ `
  query GetUserActivity {
    getUserActivity {
      id
      type
      userIdFrom
      userIdTo
      userFrom {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      externalUserFrom {
        name
        lastName
        iban
        __typename
      }
      userTo {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      beneficiary {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        bankDetails {
          name
          value
          __typename
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
        __typename
      }
      fromCurrency
      toCurrency
      sendingAmount
      exchangeRate
      receivingAmount
      fee
      transferFee
      discount {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
          __typename
        }
        __typename
      }
      reference
      purpose
      status
      steps {
        timestamp
        description
        order
        status
        __typename
      }
      errorCode
      method
      userFromNote
      userFromAttachment
      userToNote
      userToAttachment
      createdAt
      internalId
      failureReason
      adminAttachments
      debtorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
        __typename
      }
      creditorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
        __typename
      }
      endToEndId
      rateQuoteId
      runningBalanceFrom
      runningBalanceTo
      __typename
    }
  }
`;
export const getUserBalances = /* GraphQL */ `
  query GetUserBalances {
    getUserBalances {
      total
      balances {
        amount
        gbpBalance
        currency
        flag
        symbol
        color
        abbreviation
        history {
          range {
            from
            to
            __typename
          }
          data {
            date
            amount
            __typename
          }
          __typename
        }
        routingNumber
        accountNumber
        bankAddress
        bankName
        owner
        iban
        status
        __typename
      }
      __typename
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      id
      accountType
      membership {
        id
        name
        price
        dailyLimit
        monthlyLimit
        createdAt
        updatedAt
        __typename
      }
      name
      lastName
      birthdate
      country
      address
      city
      postalCode
      email
      emailVerified
      phoneCode
      phoneNumber
      phoneNumberVerified
      kyc {
        status
        result
        caseId
        applicantId
        reason
        __typename
      }
      beneficiaries {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        bankDetails {
          name
          value
          __typename
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
        __typename
      }
      externalBankAccounts {
        name
        number
        iban
        swift
        sort
        __typename
      }
      poundsAccount {
        id
        name
        label
        type
        currency
        iban
        bban
        balance
        lastTx
        __typename
      }
      invitedBy {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      readNotifications
      readOnboardings
      amountsSent {
        daily
        monthly
        __typename
      }
      profileImage
      paymentMethods {
        last4
        scheme
        provider
        customerId
        expiryMonth
        id
        expiryYear
        type
        __typename
      }
      companyInfo {
        tradingName
        primaryHolderName
        industryType
        registeredAddress
        businessDescription
        annualTurnover
        stablishmentDate
        tradingStartDate
        transactionVolumen
        shareholders {
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          __typename
        }
        internationalActivity
        kybCompanyCode
        __typename
      }
      pushNotificationsConfig {
        token
        endpointArn
        __typename
      }
      smsMarketingConsent
      pushMarketingConsent
      emailMarketingConsent
      smsAllowed
      pushAllowed
      emailAllowed
      forbiddenCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      intercomProfileUrl
      accountStatus
      type
      passwordResetAt
      passwordResetBy
      customerId
      businessDetail
      shareholders
      SignUpAddressPersonalDetails
      SignUpFinancialDetails
      SignUpIndividualPersonalDetails
      transactionsInfo
      businessType
      author
      __typename
    }
  }
`;
export const getMessages = /* GraphQL */ `
  query GetMessages($conversationId: String!) {
    getMessages(conversationId: $conversationId) {
      conversationId
      type
      timestamp
      sender {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      content
      read
      __typename
    }
  }
`;
export const getUserNotifications = /* GraphQL */ `
  query GetUserNotifications($fromDate: AWSDateTime) {
    getUserNotifications(fromDate: $fromDate) {
      id
      type
      title
      body
      url
      user {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      notificationUserId
      notifSendApproach
      meta
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getDiscountByCode = /* GraphQL */ `
  query GetDiscountByCode($code: String) {
    getDiscountByCode(code: $code) {
      id
      code
      type
      amount
      totalUses
      totalUniqueUses
      expire
      discountUses {
        userId
        date
        __typename
      }
      __typename
    }
  }
`;
export const kybSearchCompany = /* GraphQL */ `
  query KybSearchCompany($company: String, $country: String) {
    kybSearchCompany(company: $company, country: $country) {
      success
      data
      __typename
    }
  }
`;
export const kybGetCaseDetails = /* GraphQL */ `
  query KybGetCaseDetails {
    kybGetCaseDetails {
      success
      data
      __typename
    }
  }
`;
export const getRatesComparison = /* GraphQL */ `
  query GetRatesComparison($fromCurrency: String, $toCurrency: String, $amount: String) {
    getRatesComparison(fromCurrency: $fromCurrency, toCurrency: $toCurrency, amount: $amount) {
      success
      data
      errorCode
      __typename
    }
  }
`;
export const verifyLast4DigitsOfPhone = /* GraphQL */ `
  query VerifyLast4DigitsOfPhone($last4Digits: String) {
    verifyLast4DigitsOfPhone(last4Digits: $last4Digits) {
      oneTimePassword
      __typename
    }
  }
`;
export const listDirectDebitMandates = /* GraphQL */ `
  query ListDirectDebitMandates {
    listDirectDebitMandates {
      items {
        userId
        mandateId
        originatorInformation {
          accountName
          sortCode
          accountNumber
          __typename
        }
        payerInformation {
          accountName
          sortCode
          accountNumber
          __typename
        }
        reference
        status
        createdTimestamp
        lastPaymentDate
        lastPaymentAmount
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExchangeRate = /* GraphQL */ `
  query GetExchangeRate(
    $fromCurrency: String
    $toCurrency: String
    $toAmount: Float
    $fromAmount: Float
  ) {
    getExchangeRate(
      fromCurrency: $fromCurrency
      toCurrency: $toCurrency
      toAmount: $toAmount
      fromAmount: $fromAmount
    ) {
      fromCurrency
      toCurrency
      fromAmount
      toAmount
      exchangeRate
      fee
      error
      __typename
    }
  }
`;
export const getFlatRates = /* GraphQL */ `
  query GetFlatRates {
    getFlatRates {
      from
      to
      amount
      __typename
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      id
      iso
      iso2
      region {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      defaultCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      active
      name
      phoneCode
      order
      companyRequirements
      personalRequirements
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCountrys = /* GraphQL */ `
  query ListCountrys($filter: ModelCountryFilterInput, $limit: Int, $nextToken: String) {
    listCountrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCurrency = /* GraphQL */ `
  query GetCurrency($id: ID!) {
    getCurrency(id: $id) {
      id
      iso
      active
      name
      walletBgColor
      walletTextColor
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCurrencys = /* GraphQL */ `
  query ListCurrencys($filter: ModelCurrencyFilterInput, $limit: Int, $nextToken: String) {
    listCurrencys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCurrencyRequirements = /* GraphQL */ `
  query ListCurrencyRequirements($filter: ListCurrencyRequirementsInput) {
    listCurrencyRequirements(filter: $filter) {
      items {
        currency
        type
        name
        requirements
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const getPair = /* GraphQL */ `
  query GetPair($id: ID!) {
    getPair(id: $id) {
      id
      status
      from {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      to {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      regionDestination {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      destination {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
        __typename
      }
      rates {
        intervalIni
        intervalEnd
        rateMode
        rateFrom
        rateTo
        feeMode
        fee
        percentageFee
        __typename
      }
      lastExchangeRateBuy
      lastExchangeRateSell
      createdAt
      updatedAt
      sellRateOverride
      profitMargin {
        type
        value
        __typename
      }
      __typename
    }
  }
`;
export const getPairWithRate = /* GraphQL */ `
  query GetPairWithRate($input: PairWithRateInput) {
    getPairWithRate(input: $input) {
      id
      status
      from {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      to {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      regionDestination {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      destination {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
        __typename
      }
      rates {
        intervalIni
        intervalEnd
        rateMode
        rateFrom
        rateTo
        feeMode
        fee
        percentageFee
        __typename
      }
      lastExchangeRateBuy
      lastExchangeRateSell
      createdAt
      updatedAt
      sellRateOverride
      profitMargin {
        type
        value
        __typename
      }
      __typename
    }
  }
`;
export const listPairs = /* GraphQL */ `
  query ListPairs($filter: ModelPairFilterInput, $limit: Int, $nextToken: String) {
    listPairs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        from {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        to {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        regionDestination {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        destination {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        rates {
          intervalIni
          intervalEnd
          rateMode
          rateFrom
          rateTo
          feeMode
          fee
          percentageFee
          __typename
        }
        lastExchangeRateBuy
        lastExchangeRateSell
        createdAt
        updatedAt
        sellRateOverride
        profitMargin {
          type
          value
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      email
      active
      accountType
      permissions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams($filter: ModelTeamFilterInput, $limit: Int, $nextToken: String) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        active
        accountType
        permissions
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFee = /* GraphQL */ `
  query GetFee($id: ID!) {
    getFee(id: $id) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      paymentType
      rates {
        intervalIni
        intervalEnd
        feePercent
        feeAbsolute
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFees = /* GraphQL */ `
  query ListFees($filter: ModelFeeFilterInput, $limit: Int, $nextToken: String) {
    listFees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        paymentType
        rates {
          intervalIni
          intervalEnd
          feePercent
          feeAbsolute
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLocalPair = /* GraphQL */ `
  query GetLocalPair($id: ID!) {
    getLocalPair(id: $id) {
      id
      currency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      region {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      country {
        id
        iso
        iso2
        region {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        defaultCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        active
        name
        phoneCode
        order
        companyRequirements
        personalRequirements
        createdAt
        updatedAt
        __typename
      }
      rates {
        intervalIni
        intervalEnd
        feeMode
        fee
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLocalPairs = /* GraphQL */ `
  query ListLocalPairs($filter: ModelLocalPairFilterInput, $limit: Int, $nextToken: String) {
    listLocalPairs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        region {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        rates {
          intervalIni
          intervalEnd
          feeMode
          fee
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      accountType
      freelanceBusinessType
      membership {
        id
        name
        price
        dailyLimit
        monthlyLimit
        createdAt
        updatedAt
        __typename
      }
      name
      lastName
      birthdate
      country
      address
      city
      postalCode
      email
      emailVerified
      phoneCode
      phoneNumber
      phoneNumberVerified
      kyc {
        status
        result
        caseId
        applicantId
        reason
        __typename
      }
      beneficiaries {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        bankDetails {
          name
          value
          __typename
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
        __typename
      }
      externalBankAccounts {
        name
        number
        iban
        swift
        sort
        __typename
      }
      poundsAccount {
        id
        name
        label
        type
        currency
        iban
        bban
        balance
        lastTx
        __typename
      }
      invitedBy {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      readNotifications
      readOnboardings
      amountsSent {
        daily
        monthly
        __typename
      }
      profileImage
      paymentMethods {
        last4
        scheme
        provider
        customerId
        expiryMonth
        id
        expiryYear
        type
        __typename
      }
      companyInfo {
        tradingName
        primaryHolderName
        industryType
        registeredAddress
        businessDescription
        annualTurnover
        stablishmentDate
        tradingStartDate
        transactionVolumen
        shareholders {
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          __typename
        }
        internationalActivity
        kybCompanyCode
        __typename
      }
      pushNotificationsConfig {
        token
        endpointArn
        __typename
      }
      smsMarketingConsent
      pushMarketingConsent
      emailMarketingConsent
      smsAllowed
      pushAllowed
      emailAllowed
      forbiddenCurrency {
        id
        iso
        active
        name
        walletBgColor
        walletTextColor
        order
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      intercomProfileUrl
      accountStatus
      type
      passwordResetAt
      passwordResetBy
      customerId
      businessDetail
      shareholders
      SignUpAddressPersonalDetails
      SignUpFinancialDetails
      SignUpIndividualPersonalDetails
      transactionsInfo
      businessType
      author
      __typename
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles($filter: ModelUserProfileFilterInput, $limit: Int, $nextToken: String) {
    listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      type
      userIdFrom
      userIdTo
      userFrom {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      externalUserFrom {
        name
        lastName
        iban
        __typename
      }
      userTo {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      beneficiary {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        bankDetails {
          name
          value
          __typename
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
        __typename
      }
      fromCurrency
      toCurrency
      sendingAmount
      exchangeRate
      receivingAmount
      fee
      transferFee
      discount {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
          __typename
        }
        __typename
      }
      reference
      purpose
      status
      steps {
        timestamp
        description
        order
        status
        __typename
      }
      errorCode
      method
      userFromNote
      userFromAttachment
      userToNote
      userToAttachment
      createdAt
      internalId
      failureReason
      adminAttachments
      debtorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
        __typename
      }
      creditorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
        __typename
      }
      endToEndId
      rateQuoteId
      runningBalanceFrom
      runningBalanceTo
      __typename
    }
  }
`;
export const getTransactionV2 = /* GraphQL */ `
  query GetTransactionV2($id: ID!) {
    getTransactionV2(id: $id) {
      userId
      id
      type
      action
      country
      lng
      city
      region
      lat
      ipAddress
      metadataId
      reference
      internalId
      amount
      debtorSource
      debtorName
      debtorUserId
      debtorCardNumber
      debtorIban
      debtorAccountNumber
      debtorSortCode
      exchangeRate
      fee
      transferFee
      scope
      creditorAddress
      iban
      accountType
      creditorSource
      creditorName
      creditorUserId
      creditorIban
      creditorAccountNumber
      creditorSortCode
      swift
      currency
      endToEndId
      status
      direction
      expireAt
      destinationCurrency
      destinationAmount
      timestamp
      subType
      attachment
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const searchTransactions = /* GraphQL */ `
  query SearchTransactions(
    $filter: ModelTransactionSearchInput
    $limit: Int
    $nextToken: String
    $value: String
    $key: TransactionInputQueryType
  ) {
    searchTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      value: $value
      key: $key
    ) {
      items {
        id
        type
        userIdFrom
        userIdTo
        userFrom {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        externalUserFrom {
          name
          lastName
          iban
          __typename
        }
        userTo {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        beneficiary {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        fromCurrency
        toCurrency
        sendingAmount
        exchangeRate
        receivingAmount
        fee
        transferFee
        discount {
          id
          code
          type
          amount
          totalUses
          totalUniqueUses
          expire
          discountUses {
            userId
            date
            __typename
          }
          __typename
        }
        reference
        purpose
        status
        steps {
          timestamp
          description
          order
          status
          __typename
        }
        errorCode
        method
        userFromNote
        userFromAttachment
        userToNote
        userToAttachment
        createdAt
        internalId
        failureReason
        adminAttachments
        debtorAccount {
          addressLine1
          addressLine2
          country
          postcode
          name
          ownerName
          iban
          bban
          descriptor
          institutionName
          transactionOwnerName
          __typename
        }
        creditorAccount {
          addressLine1
          addressLine2
          country
          postcode
          name
          ownerName
          iban
          bban
          descriptor
          institutionName
          transactionOwnerName
          __typename
        }
        endToEndId
        rateQuoteId
        runningBalanceFrom
        runningBalanceTo
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: ModelCustomerSearchInput
    $limit: Int
    $nextToken: String
    $value: String
    $key: CustomerInputQueryType
  ) {
    searchCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      value: $value
      key: $key
    ) {
      items {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
    $createdDayHour: String
  ) {
    listTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      createdDayHour: $createdDayHour
    ) {
      items {
        id
        type
        userIdFrom
        userIdTo
        userFrom {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        externalUserFrom {
          name
          lastName
          iban
          __typename
        }
        userTo {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        beneficiary {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        fromCurrency
        toCurrency
        sendingAmount
        exchangeRate
        receivingAmount
        fee
        transferFee
        discount {
          id
          code
          type
          amount
          totalUses
          totalUniqueUses
          expire
          discountUses {
            userId
            date
            __typename
          }
          __typename
        }
        reference
        purpose
        status
        steps {
          timestamp
          description
          order
          status
          __typename
        }
        errorCode
        method
        userFromNote
        userFromAttachment
        userToNote
        userToAttachment
        createdAt
        internalId
        failureReason
        adminAttachments
        debtorAccount {
          addressLine1
          addressLine2
          country
          postcode
          name
          ownerName
          iban
          bban
          descriptor
          institutionName
          transactionOwnerName
          __typename
        }
        creditorAccount {
          addressLine1
          addressLine2
          country
          postcode
          name
          ownerName
          iban
          bban
          descriptor
          institutionName
          transactionOwnerName
          __typename
        }
        endToEndId
        rateQuoteId
        runningBalanceFrom
        runningBalanceTo
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConfig = /* GraphQL */ `
  query GetConfig($id: ID!) {
    getConfig(id: $id) {
      id
      key
      value
      __typename
    }
  }
`;
export const listConfigs = /* GraphQL */ `
  query ListConfigs($filter: ModelConfigFilterInput, $limit: Int, $nextToken: String) {
    listConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        value
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDiscount = /* GraphQL */ `
  query GetDiscount($id: ID!) {
    getDiscount(id: $id) {
      id
      code
      type
      amount
      totalUses
      totalUniqueUses
      expire
      discountUses {
        userId
        date
        __typename
      }
      __typename
    }
  }
`;
export const listDiscounts = /* GraphQL */ `
  query ListDiscounts($filter: ModelDiscountFilterInput, $limit: Int, $nextToken: String) {
    listDiscounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOnboarding = /* GraphQL */ `
  query GetOnboarding($id: ID!) {
    getOnboarding(id: $id) {
      id
      showTopSteps
      topText
      showBottomSteps
      bottomText
      type
      showLogo
      ctaText
      slideImage
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOnboardings = /* GraphQL */ `
  query ListOnboardings($filter: ModelOnboardingFilterInput, $limit: Int, $nextToken: String) {
    listOnboardings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        showTopSteps
        topText
        showBottomSteps
        bottomText
        type
        showLogo
        ctaText
        slideImage
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      title
      body
      url
      user {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      notificationUserId
      notifSendApproach
      meta
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications($filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title
        body
        url
        user {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        notificationUserId
        notifSendApproach
        meta
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMembership = /* GraphQL */ `
  query GetMembership($id: ID!) {
    getMembership(id: $id) {
      id
      name
      price
      dailyLimit
      monthlyLimit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMemberships = /* GraphQL */ `
  query ListMemberships($filter: ModelMembershipFilterInput, $limit: Int, $nextToken: String) {
    listMemberships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        price
        dailyLimit
        monthlyLimit
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRegion = /* GraphQL */ `
  query GetRegion($id: ID!) {
    getRegion(id: $id) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRegions = /* GraphQL */ `
  query ListRegions($filter: ModelRegionFilterInput, $limit: Int, $nextToken: String) {
    listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserNote = /* GraphQL */ `
  query GetUserNote($id: ID!) {
    getUserNote(id: $id) {
      id
      userId
      adminId
      timestamp
      adminName
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserNotes = /* GraphQL */ `
  query ListUserNotes($filter: ModelUserNoteFilterInput, $limit: Int, $nextToken: String) {
    listUserNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        adminId
        timestamp
        adminName
        note
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserLogs = /* GraphQL */ `
  query ListUserLogs($filter: ModelUserLogFilterInput, $limit: Int, $nextToken: String) {
    listUserLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        platform
        location
        ip
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listBalanceLogs = /* GraphQL */ `
  query ListBalanceLogs($userId: String, $transactionId: String, $limit: Int, $nextToken: String) {
    listBalanceLogs(
      userId: $userId
      transactionId: $transactionId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        range
        amount
        createdTimestamp
        currency
        endToEndId
        source
        transactionId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserBalances = /* GraphQL */ `
  query ListUserBalances($userId: String, $currency: String, $limit: Int, $nextToken: String) {
    listUserBalances(userId: $userId, currency: $currency, limit: $limit, nextToken: $nextToken) {
      items {
        id
        balance
        createdTimestamp
        currency
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCurrencyIBAN = /* GraphQL */ `
  query GetCurrencyIBAN($id: ID!, $multiCurrency: Boolean) {
    getCurrencyIBAN(id: $id, multiCurrency: $multiCurrency) {
      id
      AccountId
      BatchId
      iban
      Identifiers {
        Identifier
        Kind
        __typename
      }
      owner
      status
      timestamp
      TimestampCreated
      VirtualAccountId
      balance
      currency
      name
      type
      history {
        date
        amount
        __typename
      }
      __typename
    }
  }
`;
export const getUserWallets = /* GraphQL */ `
  query GetUserWallets($id: ID!) {
    getUserWallets(id: $id) {
      id
      currency
      balance
      history
      routingNumber
      accountNumber
      bankAddress
      bankName
      status
      type
      iban
      __typename
    }
  }
`;
export const getUserTransactions = /* GraphQL */ `
  query GetUserTransactions($id: ID!) {
    getUserTransactions(id: $id) {
      id
      type
      userIdFrom
      userIdTo
      userFrom {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      externalUserFrom {
        name
        lastName
        iban
        __typename
      }
      userTo {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      beneficiary {
        id
        status
        userId
        accountType
        name
        lastName
        country {
          id
          iso
          iso2
          region {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          defaultCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          active
          name
          phoneCode
          order
          companyRequirements
          personalRequirements
          createdAt
          updatedAt
          __typename
        }
        address
        city
        postalCode
        email
        phoneCode
        phoneNumber
        currency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        bankDetails {
          name
          value
          __typename
        }
        userProfileId
        usageLevel
        skipInternalCheck
        currencyRequirementType
        beneficiaryCountryId
        beneficiaryCurrencyId
        __typename
      }
      fromCurrency
      toCurrency
      sendingAmount
      exchangeRate
      receivingAmount
      fee
      transferFee
      discount {
        id
        code
        type
        amount
        totalUses
        totalUniqueUses
        expire
        discountUses {
          userId
          date
          __typename
        }
        __typename
      }
      reference
      purpose
      status
      steps {
        timestamp
        description
        order
        status
        __typename
      }
      errorCode
      method
      userFromNote
      userFromAttachment
      userToNote
      userToAttachment
      createdAt
      internalId
      failureReason
      adminAttachments
      debtorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
        __typename
      }
      creditorAccount {
        addressLine1
        addressLine2
        country
        postcode
        name
        ownerName
        iban
        bban
        descriptor
        institutionName
        transactionOwnerName
        __typename
      }
      endToEndId
      rateQuoteId
      runningBalanceFrom
      runningBalanceTo
      __typename
    }
  }
`;
export const getUserNotificationsBo = /* GraphQL */ `
  query GetUserNotificationsBo($id: ID!) {
    getUserNotificationsBo(id: $id) {
      id
      type
      title
      body
      url
      user {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      notificationUserId
      notifSendApproach
      meta
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getNotificationById = /* GraphQL */ `
  query GetNotificationById($id: ID!) {
    getNotificationById(id: $id) {
      id
      type
      title
      body
      url
      user {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      notificationUserId
      notifSendApproach
      meta
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getUserSMSNotificationsBo = /* GraphQL */ `
  query GetUserSMSNotificationsBo($id: ID!) {
    getUserSMSNotificationsBo(id: $id) {
      id
      text
      user {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      adminId
      adminName
      createAt
      success
      error
      __typename
    }
  }
`;
export const listEmailTemplates = /* GraphQL */ `
  query ListEmailTemplates {
    listEmailTemplates {
      TemplateName
      HtmlPart
      SubjectPart
      TextPart
      __typename
    }
  }
`;
export const newSearchTransactions = /* GraphQL */ `
  query NewSearchTransactions(
    $key: NewTransactionKey
    $value: String
    $sort: TransactionSort
    $filter: NewTransactionFilterInput
    $limit: Int
    $offset: Int
  ) {
    newSearchTransactions(
      key: $key
      value: $value
      sort: $sort
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      items {
        id
        type
        userIdFrom
        userIdTo
        userFrom {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        externalUserFrom {
          name
          lastName
          iban
          __typename
        }
        userTo {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        beneficiary {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        fromCurrency
        toCurrency
        sendingAmount
        exchangeRate
        receivingAmount
        fee
        transferFee
        discount {
          id
          code
          type
          amount
          totalUses
          totalUniqueUses
          expire
          discountUses {
            userId
            date
            __typename
          }
          __typename
        }
        reference
        purpose
        status
        steps {
          timestamp
          description
          order
          status
          __typename
        }
        errorCode
        method
        userFromNote
        userFromAttachment
        userToNote
        userToAttachment
        createdAt
        internalId
        failureReason
        adminAttachments
        debtorAccount {
          addressLine1
          addressLine2
          country
          postcode
          name
          ownerName
          iban
          bban
          descriptor
          institutionName
          transactionOwnerName
          __typename
        }
        creditorAccount {
          addressLine1
          addressLine2
          country
          postcode
          name
          ownerName
          iban
          bban
          descriptor
          institutionName
          transactionOwnerName
          __typename
        }
        endToEndId
        rateQuoteId
        runningBalanceFrom
        runningBalanceTo
        __typename
      }
      total
      __typename
    }
  }
`;
export const boMCCYSearchTransactions = /* GraphQL */ `
  query BoMCCYSearchTransactions(
    $key: BOMCCYTransactionKey
    $value: String
    $sort: TransactionSort
    $filter: BOMCCYTransactionFilterInput
    $limit: Int
    $offset: Int
  ) {
    boMCCYSearchTransactions(
      key: $key
      value: $value
      sort: $sort
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      items {
        id
        userId
        username
        currency
        amount
        reference
        status
        steps {
          timestamp
          description
          order
          __typename
        }
        createdAt
        failureReason
        endToEndId
        beneficiaryName
        beneficiaryAddress
        beneficiaryCity
        beneficiaryPostcode
        beneficiaryCountry
        beneficiarySwift
        beneficiaryIBAN
        beneficiaryCountryCode
        beneficiaryAccountNumber
        __typename
      }
      total
      __typename
    }
  }
`;
export const listAllTransactions = /* GraphQL */ `
  query ListAllTransactions(
    $currency: String
    $startDate: String
    $endDate: String
    $limit: Int
    $offset: Int
    $type: AllTransactionType
  ) {
    listAllTransactions(
      currency: $currency
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      offset: $offset
      type: $type
    ) {
      items {
        endToEndId
        status
        amount
        createdAt
        beneficiaryName
        __typename
      }
      total
      __typename
    }
  }
`;
export const newSearchCustomers = /* GraphQL */ `
  query NewSearchCustomers(
    $key: NewCustomerKey
    $value: String
    $sort: CustomerSort
    $filter: NewCustomerFilterInput
    $limit: Int
    $offset: Int
  ) {
    newSearchCustomers(
      key: $key
      value: $value
      sort: $sort
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      items {
        id
        accountType
        membership {
          id
          name
          price
          dailyLimit
          monthlyLimit
          createdAt
          updatedAt
          __typename
        }
        name
        lastName
        birthdate
        country
        address
        city
        postalCode
        email
        emailVerified
        phoneCode
        phoneNumber
        phoneNumberVerified
        kyc {
          status
          result
          caseId
          applicantId
          reason
          __typename
        }
        beneficiaries {
          id
          status
          userId
          accountType
          name
          lastName
          country {
            id
            iso
            iso2
            active
            name
            phoneCode
            order
            companyRequirements
            personalRequirements
            createdAt
            updatedAt
            __typename
          }
          address
          city
          postalCode
          email
          phoneCode
          phoneNumber
          currency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          bankDetails {
            name
            value
            __typename
          }
          userProfileId
          usageLevel
          skipInternalCheck
          currencyRequirementType
          beneficiaryCountryId
          beneficiaryCurrencyId
          __typename
        }
        externalBankAccounts {
          name
          number
          iban
          swift
          sort
          __typename
        }
        poundsAccount {
          id
          name
          label
          type
          currency
          iban
          bban
          balance
          lastTx
          __typename
        }
        invitedBy {
          id
          accountType
          membership {
            id
            name
            price
            dailyLimit
            monthlyLimit
            createdAt
            updatedAt
            __typename
          }
          name
          lastName
          birthdate
          country
          address
          city
          postalCode
          email
          emailVerified
          phoneCode
          phoneNumber
          phoneNumberVerified
          kyc {
            status
            result
            caseId
            applicantId
            reason
            __typename
          }
          beneficiaries {
            id
            status
            userId
            accountType
            name
            lastName
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            userProfileId
            usageLevel
            skipInternalCheck
            currencyRequirementType
            beneficiaryCountryId
            beneficiaryCurrencyId
            __typename
          }
          externalBankAccounts {
            name
            number
            iban
            swift
            sort
            __typename
          }
          poundsAccount {
            id
            name
            label
            type
            currency
            iban
            bban
            balance
            lastTx
            __typename
          }
          invitedBy {
            id
            accountType
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            emailVerified
            phoneCode
            phoneNumber
            phoneNumberVerified
            readNotifications
            readOnboardings
            profileImage
            smsMarketingConsent
            pushMarketingConsent
            emailMarketingConsent
            smsAllowed
            pushAllowed
            emailAllowed
            createdAt
            updatedAt
            intercomProfileUrl
            accountStatus
            type
            passwordResetAt
            passwordResetBy
            customerId
            businessDetail
            shareholders
            SignUpAddressPersonalDetails
            SignUpFinancialDetails
            SignUpIndividualPersonalDetails
            transactionsInfo
            businessType
            author
            __typename
          }
          readNotifications
          readOnboardings
          amountsSent {
            daily
            monthly
            __typename
          }
          profileImage
          paymentMethods {
            last4
            scheme
            provider
            customerId
            expiryMonth
            id
            expiryYear
            type
            __typename
          }
          companyInfo {
            tradingName
            primaryHolderName
            industryType
            registeredAddress
            businessDescription
            annualTurnover
            stablishmentDate
            tradingStartDate
            transactionVolumen
            internationalActivity
            kybCompanyCode
            __typename
          }
          pushNotificationsConfig {
            token
            endpointArn
            __typename
          }
          smsMarketingConsent
          pushMarketingConsent
          emailMarketingConsent
          smsAllowed
          pushAllowed
          emailAllowed
          forbiddenCurrency {
            id
            iso
            active
            name
            walletBgColor
            walletTextColor
            order
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          intercomProfileUrl
          accountStatus
          type
          passwordResetAt
          passwordResetBy
          customerId
          businessDetail
          shareholders
          SignUpAddressPersonalDetails
          SignUpFinancialDetails
          SignUpIndividualPersonalDetails
          transactionsInfo
          businessType
          author
          __typename
        }
        readNotifications
        readOnboardings
        amountsSent {
          daily
          monthly
          __typename
        }
        profileImage
        paymentMethods {
          last4
          scheme
          provider
          customerId
          expiryMonth
          id
          expiryYear
          type
          __typename
        }
        companyInfo {
          tradingName
          primaryHolderName
          industryType
          registeredAddress
          businessDescription
          annualTurnover
          stablishmentDate
          tradingStartDate
          transactionVolumen
          shareholders {
            name
            lastName
            birthdate
            country
            address
            city
            postalCode
            email
            phoneCode
            phoneNumber
            __typename
          }
          internationalActivity
          kybCompanyCode
          __typename
        }
        pushNotificationsConfig {
          token
          endpointArn
          __typename
        }
        smsMarketingConsent
        pushMarketingConsent
        emailMarketingConsent
        smsAllowed
        pushAllowed
        emailAllowed
        forbiddenCurrency {
          id
          iso
          active
          name
          walletBgColor
          walletTextColor
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        intercomProfileUrl
        accountStatus
        type
        passwordResetAt
        passwordResetBy
        customerId
        businessDetail
        shareholders
        SignUpAddressPersonalDetails
        SignUpFinancialDetails
        SignUpIndividualPersonalDetails
        transactionsInfo
        businessType
        author
        __typename
      }
      total
      __typename
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles {
    listRoles {
      creationDate
      description
      roleName
      lastModifiedDate
      precedence
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers {
    listUsers {
      userName
      attributes {
        name
        value
        __typename
      }
      userCreateDate
      userLastModifiedDate
      enabled
      userStatus
      roles
      __typename
    }
  }
`;
export const listMCCYTransactions = /* GraphQL */ `
  query ListMCCYTransactions($currency: String, $startDate: String, $endDate: String) {
    listMCCYTransactions(currency: $currency, startDate: $startDate, endDate: $endDate)
  }
`;
export const listMCCYBalances = /* GraphQL */ `
  query ListMCCYBalances {
    listMCCYBalances {
      currency
      available
      actual
      __typename
    }
  }
`;
export const listFXTrades = /* GraphQL */ `
  query ListFXTrades($createdDay: String) {
    listFXTrades(createdDay: $createdDay)
  }
`;
export const RDSTest = /* GraphQL */ `
  query RDSTest {
    RDSTest
  }
`;
export const listTermsCondition = /* GraphQL */ `
  query ListTermsCondition($limit: Int, $offset: Int) {
    listTermsCondition(limit: $limit, offset: $offset) {
      items {
        id
        platform
        version
        content
        link
        createdAt
        updatedAt
        __typename
      }
      total
      __typename
    }
  }
`;
export const getMonthlyStatement = /* GraphQL */ `
  query GetMonthlyStatement($input: GetMonthlyStatementInput) {
    getMonthlyStatement(input: $input) {
      currency
      currentRang
      items {
        currency
        incoming
        outgoing
        balance
        date
        month
        __typename
      }
      __typename
    }
  }
`;
export const fetchRelatedShareHolders = /* GraphQL */ `
  query FetchRelatedShareHolders($id: ID!) {
    fetchRelatedShareHolders(id: $id) {
      id
      name
      lastName
      phoneNumber
      boardStatus
      accountType
      accountStatus
      email
      applicantId
      __typename
    }
  }
`;
export const kybCompanyFilings = /* GraphQL */ `
  query KybCompanyFilings($companyId: String, $countryCode: String) {
    kybCompanyFilings(companyId: $companyId, countryCode: $countryCode) {
      companyId
      countryCode
      filings {
        category
        code
        date
        description
        fileName
        fileType
        __typename
      }
      pagination {
        limit
        offset
        total
        __typename
      }
      requestId
      status
      __typename
    }
  }
`;
export const kybCompanyKycSearch = /* GraphQL */ `
  query KybCompanyKycSearch($companyId: String) {
    kybCompanyKycSearch(companyId: $companyId) {
      created
      searchHits {
        searchHit {
          aka {
            name
            __typename
          }
          amlTypes
          associates {
            association
            name
            __typename
          }
          entityType
          lastUpdated
          media {
            date
            snippet
            title
            url
            __typename
          }
          name
          sourceInformation {
            __typename
          }
          __typename
        }
        __typename
      }
      searchId
      totalHits
      totalMatches
      updated
      __typename
    }
  }
`;
