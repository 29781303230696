import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  beneficiariesContainer: {
    padding: '16px',
    fontSize: '14px',
    height: '648px',
    marginTop: '16px',
    overflowY: 'auto',
  },
  beneficiaryRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '24px',
    cursor: 'pointer',
  },
  beneficiaryText: {
    marginLeft: '8px',
    '& p, strong': {
      color: '#a5a6ae',
    },
    '& h3': {
      fontWeight: '500',
    },
  },
  accountDetails: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '8px',
  },
  profilePicContainer: {
    position: 'relative',
  },
  smallIcon: {
    position: 'absolute',
    right: '-10px',
    top: '24px',
  },
  noBeneficiaries: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  customerFilterModalContainer: {
    backgroundColor: '#f1f1f3',
    width: '50%',
    borderRadius: '20px',
    marginLeft: "auto",
    marginRight: "10px",
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    padding: '5px 10px',
    fontSize: '14px',
    '& header': {
      fontSize: '20px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  statusRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent:"space-between"
  },
  defaultStatus: {
    borderRadius: '12px',
    backgroundColor: '#f1f1f3',
    color: '#a5a6ae',
    fontSize: '12px',
    textAlign: 'center',
    padding: '5px 6px',
    cursor: 'pointer',
  },
  createdActive: {
    backgroundColor: 'white',
    color: theme.palette.icons.nav,
  },
}));
