import React, { useEffect, useState } from 'react';
import { entitiesEnum } from '../../constants';
import { compareByDate } from '../../utils';
import { GenericTable } from '../generic-table';
import { PairsTable } from '../pairs/components/table/pairs-table';
import { MultiCurrenciesTable } from '../../views/multi-currency-view';
import { useStyles } from './entity-table.hooks';
import { useSelector } from 'react-redux';
import { KybDashboard, TableDashboard } from 'views';
import FetchAndRenderForm from 'views/fetch-and-render-form/components/FetchAndRenderForm';
import { getCurrentUser } from 'services';

export const EntityTable = ({ entity, listData }) => {
  const [filters, setFilters] = useState({});
  const [tableData, setTableData] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const {
    loading,
    error,
    data,
    handleRefreshData,
    tableFields,
    canOrder,
    setLoading,
    page,
    setUpdate,
    limit,
    searchTransactions,
    isSearchMode,
    setIsSearchMode,
    hasNextPageTransactionSearchByName,
    noOfPagesTransactionSearchResults,
    transactionSearchResultsPage,
    setTransactionSearchResultsPage,
    customerSearchResultsPage,
    hasNextPageCustomerSearch,
    noOfPagesCustomerSearchResults,
    setCustomerSearchResultsPage,
  } = listData;

  const newFilter = useSelector(state => state.customerFilterByAccountType);
  const classes = useStyles();

  useEffect(() => {
    if (!!data || data?.length === 0) setTableData([]);
    if (data?.length) {
      const orderedData = data?.sort(compareByDate({ canOrder }));
      setTableData(orderedData);
    }
    if (newFilter !== '' && entity === 'UserProfile') {
      const filterData = data.filter(item => item.accountType === newFilter);
      const orderedData = filterData?.sort(compareByDate({ canOrder }));
      setTableData(orderedData);
    }
  }, [data, canOrder, newFilter]);

  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const { accessToken } = await getCurrentUser();
        const response = await fetch(`${process.env.REACT_APP_STATEMENT_URL}/user/admin-menu`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        const entityTitles = data.resources.map(item => item.title.toUpperCase());
        setEntityList(entityTitles);
      } catch (error) {
        console.error('Failed to fetch and update routes:', error);
      }
    };

    fetchRoutes();
  }, []);

  if (entity === entitiesEnum.PAIR)
    return <PairsTable data={tableData} loading={loading} handleRefreshData={handleRefreshData} />;

  if (entity === entitiesEnum.MULTICURRENCY)
    return (
      <MultiCurrenciesTable
        data={tableData}
        loading={loading}
        handleRefreshData={handleRefreshData}
      />
    );

  if (Array.isArray(entityList) && entityList.length !== 0) {
    const matchingItem = entityList.find(item => entity === entitiesEnum[item]);
    if (matchingItem) {
      return <TableDashboard />;
    }
  }

  if (entity === entitiesEnum.KYB) {
    return <KybDashboard />;
  }

  if (entity === entitiesEnum.FORM) {
    return <FetchAndRenderForm />;
  }

  return (
    <div className={classes.content}>
      <GenericTable
        canOrder={canOrder}
        data={tableData}
        entity={entity}
        error={error}
        loading={loading}
        onRefreshData={handleRefreshData}
        page={page}
        setLoading={setLoading}
        tableFields={tableFields}
        filters={filters}
        setFilters={setFilters}
        setUpdate={setUpdate}
        showCreateAdminModal={false}
        limit={limit}
        searchTransactions={searchTransactions}
        isSearchMode={isSearchMode}
        setIsSearchMode={setIsSearchMode}
        hasNextPageTransactionSearchByName={hasNextPageTransactionSearchByName}
        noOfPagesTransactionSearchResults={noOfPagesTransactionSearchResults}
        transactionSearchResultsPage={transactionSearchResultsPage}
        setTransactionSearchResultsPage={setTransactionSearchResultsPage}
        customerSearchResultsPage={customerSearchResultsPage}
        hasNextPageCustomerSearch={hasNextPageCustomerSearch}
        noOfPagesCustomerSearchResults={noOfPagesCustomerSearchResults}
        setCustomerSearchResultsPage={setCustomerSearchResultsPage}
      />
    </div>
  );
};
